/**
 * Set the shipping dispatch option by rendering a section with options to choose from.
 *
 * @param {Object} selectedDispatchOption - The currently selected dispatch option.
 * @param {Function} setShippingDispatchOption - A function to set the shipping dispatch option.
 * @return {JSX.Element} The rendered section for choosing the shipping dispatch option.
 */

import { SwRadioSelect } from "@ultracommerce/react-storefront/global/src/components";

const ShippingDispatchOption = ({ selectedDispatchOption, setShippingDispatchOption }) => {
  return (
    <div className="col-12">
      <h5 className="h5 pt-1 pb-2 my-3 col-6">
        Choose your dispatch option:
      </h5>
      <SwRadioSelect
        options={[
          { name: "Ship Complete Order", value: "shipCompleteOrder" },
          {
            name: "Partial Shipment Accepted",
            value: "partialShipmentAccepted",
          },
        ]}
        onChange={(value) => {
          setShippingDispatchOption(value);
        }}
        selectedValue={selectedDispatchOption}
      />
    </div>
  );
};

export default ShippingDispatchOption;