import { createContext, useState, useContext } from "react";

// Create a modal context
export const CalculatedDataContext = createContext();

// Create a modal provider component
export const CalculatedDataProvider = ({ children }) => {
  const [calculatedData, setCalculatedData] = useState("");

  return (
    <CalculatedDataContext.Provider value={{ calculatedData, setCalculatedData }}>
      {children}
    </CalculatedDataContext.Provider>
  );
};

// Create a custom hook to use the modal context
export const useCalculateData = () => {
  return useContext(CalculatedDataContext);
};
