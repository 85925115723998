import { useState, useMemo } from 'react'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { useContentContext, useElementContext } from "@ultracommerce/react-storefront/global";
import { AddProductToQuoteModalModel } from './AddProductToQuoteModal.componentModel';
import { Sku } from '@ultracommerce/react-storefront/global/src/interface/Product';
import { ProductModifiers } from '../../Controls/ProductModifiers';
import { calStepAndMinQuantity, genModelNumber } from '../../../../utils';

export const AddProductToQuoteModalView = ({
  componentData: {
    skuID,
    skuIDs,
    isAuthenticated,
    isLoading,
    isLoadingForExisitingQuote,
    quoteName,
    requestedDeliveryDate,
    materialNumber,
    existingQuoteVal,
    existingQuotes,
  },
  setQuoteModal,
  setQuoteName,
  handleQuoteRedirect,
  addToExistingQuote,
  addToNewQuote,
  setExistingQuoteVal,
  searchListingFlag,
  product
}: {
  componentData: AddProductToQuoteModalModel;
  setQuoteModal: (isOpen: boolean) => void;
  setQuoteName: (name: string) => void;
  handleQuoteRedirect: () => void;
  addToExistingQuote: () => void;
  addToNewQuote: () => void;
  setExistingQuoteVal: (value: string) => void;
  searchListingFlag: any;
  product: any;
}) => {
  const { t } = useTranslation();
  const {
    templateData: { productDetail },
  } = useContentContext();
  const { productPageDisplayConfiguration } = productDetail || {};
  const pdpConfig: any = JSON.parse(productPageDisplayConfiguration || "{}"); //Changes - accessing the configuration for PDP

  // Add config override for displayConfig here
  const displayConfig: any = useMemo(
    () => ({
      showInputLabel: !!pdpConfig?.showInputLabel,
      showFavoritesButton: !!pdpConfig?.enableFavorites,
      showInventory: !!pdpConfig?.input?.showInventory,
      showRelatedProducts: !!pdpConfig?.showRelatedProducts,
      optionsStyle: pdpConfig?.options?.style || "dropdown",
      quantityInputMode: pdpConfig?.input?.quantityInput || "text",
      priceShowCriteria: pdpConfig?.price?.showCriteria || "Always",
      defaultSkuOnPageLoad: pdpConfig?.defaultSkuOnPageLoad || false,
      showExistingQuoteAndLists: !!pdpConfig?.showExistingQuoteAndLists,
      breadcrumbsType: pdpConfig?.breadcrumbsType || "Product type",
    }),
    [pdpConfig],
  );
  const {
    CommonModule: { Portal, SwSelect, Button, Modal },
    ProductModule
  } = useElementContext();
  const siteSetting = useSelector((state: any) => state.configuration.site.settings);
  const [skuConfiguration, setSkuConfiguration] = useState({});
  const { addressZoneName } = useSelector((state : any) => state.userReducer);
  const [sku, setSku] = useState(product?.skus?.find(({ skuID }:Sku) => skuID === product?.defaultSku_skuID) || product?.skus?.[0]);

  
  if (!isAuthenticated) {
    return (
      <Portal>
        <Modal show setShow={setQuoteModal} title={t("frontend.quote.addToQuote")} size="medium">
          <div className="container text-center">
            <div>
              <h4 className="p-0 fw-bold">{t("frontend.logintoperformaction")}</h4>
              <hr />
              <Button label={"Login to your Account"} onClick={handleQuoteRedirect} />
            </div>
          </div>
        </Modal>
      </Portal>
    );
  }

  if (searchListingFlag) {
    product.modifiers = product.modifiers || (addressZoneName === "US" ? product.modifiersUS : product.modifiersEU);
    const { stepQty, minQty } = calStepAndMinQuantity(product.modifiers, skuConfiguration);
    const productModifiers = { modifiers: product.modifiers, skuConfiguration, setSkuConfiguration }
    const productForm = {
      sku,
      sitePurpose: siteSetting.sitePurpose,
      displayConfig,
      skuConfiguration,
      modifiers: product.modifiers,
      stepQty,
      minQty,
      modelNumber: genModelNumber(sku?.skuCode, product.modifiers, skuConfiguration)
    }
    return (
      <Portal>
        <Modal show setShow={setQuoteModal} title="Select Product Configuration" size="medium">
          <div className="col-12">
            <h3 className="p-0">{sku?.skuName || sku?.productName || product.productName}</h3>
            {product?.skus?.length > 1 && (
              <div className="col-12">
                <select
                  className="custom-select rounded-pill"
                  required
                  value={sku?.skuID}
                  onChange={(e) => {
                    setSku(
                      product.skus.find(({ skuID }:Sku) => skuID === e.target.value) ||
                        product.skus[0]
                    );
                  }}
                >
                  {product.skus?.map((skuOption:Sku) => {
                    return (
                      <option key={skuOption.skuID} value={skuOption.skuID}>
                        {skuOption.calculatedSkuDefinition}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            {productModifiers && <ProductModifiers  {...productModifiers} />}
            {productForm && (Object.keys(skuConfiguration).length >= product.modifiers?.length) &&
              <div className="align-items-center">
                {/*@ts-ignore*/}
                <ProductModule.CoreComponents.ProductForm {...productForm} />
              </div>
            }
          </div>
        </Modal>
      </Portal>
    );

  } else {
    return (
      <Portal>
        <Modal show setShow={setQuoteModal} title={t("frontend.quote.addToQuote")} size="medium">
          <div className="container">
            {existingQuotes.length > 0 && (skuID || skuIDs) && (
              <>
                <form name="add-to-exisiting-quote">
                  <div className="row text-align-center">
                    <div className="col-md-12 justify-content-center">
                      <div className="form-group py-3">
                        <label htmlFor="existingQuote">{t("frontend.quote.existingQuote")}</label>
                        <SwSelect
                          id="existingQuote"
                          value={existingQuoteVal}
                          onChange={(e: any) => {
                            setExistingQuoteVal(e.target.value);
                          }}
                          options={existingQuotes.length > 0 ? existingQuotes : []}
                        />
                        {!existingQuoteVal ? <span className="form-error-msg">{t("frontend.core.required")}</span> : null}

                        <Button
                          isLoading={isLoadingForExisitingQuote}
                          classList="btn btn-primary btn-block mt-4"
                          type="button"
                          onClick={addToExistingQuote}
                          disabled={isLoadingForExisitingQuote}
                        >
                          <span>{t("frontend.quote.addToExistingQuote")}</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>

                <hr
                  className="hr-text"
                  data-content={t("frontend.account.order.template.detail.toolbar.scheduleDateModal.orText")}
                />
              </>
            )}

            <form name="add-quote">
              <div className="row text-align-center">
                <div className="col-md-12 justify-content-center">
                  <div className="form-group me-3">
                    <label htmlFor="quoteName">{t("frontend.quote.label")}</label>
                    <input
                      className="form-control"
                      type="text"
                      id="quoteName"
                      value={quoteName}
                      onChange={(e) => {
                        setQuoteName(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <Button
                      isLoading={isLoading}
                      disabled={isLoading}
                      type="submit"
                      classList="btn btn-primary btn-block mt-4 d-block m-auto"
                      onClick={addToNewQuote}
                    >
                      <span className="d-sm-inline">{t("frontend.quote.createButton")}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </Portal>
    );
  }
};
