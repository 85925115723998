import { useElementContext } from "@ultracommerce/react-storefront/global";

export const HeaderTopLeft = ({ theme, siteSetting }: { theme: any; siteSetting: any }) => {
  const {
    CommonModule: { SearchBar, DisplayField },
  } = useElementContext();

  return (
    <div className="col-md-6 d-none d-lg-flex d-flex align-items-center px-4">
      {(theme.headerStyleOption === "one" || theme.headerStyleOption === "four") && (
        <div className="d-none d-lg-flex align-items-center">
          <DisplayField fieldValue={siteSetting.companyPhone} iconClass={"telephone-fill"} />
          <DisplayField fieldValue={siteSetting.companyAddress} iconClass={"pin-map-fill"} />
        </div>
      )}
      {theme.headerStyleOption === "three" && (
        <div className="d-none d-lg-flex align-items-center my-1">
          <SearchBar searchBoxTypeaheadFlag redirectToSearchPage searchBoxPlaceholder={theme.searchPlaceholder} />
        </div>
      )}
    </div>
  );
};
