import { useElementContext } from "@ultracommerce/react-storefront/global";
import { ReactNode } from "react";
import { isAuthenticated } from '@ultracommerce/react-storefront/global';
import { logout } from '@ultracommerce/react-storefront/global'
import { useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";
import { SocialLinks } from "../../SocialLinks/SocialLinks";

export const HeaderTopRight = ({
  ribbons,
  theme,
  siteSetting,
}: {
  ribbons: ReactNode[];
  theme: any;
  siteSetting: any;
}) => {
  const {
    CommonModule: { MiniCart },
  } = useElementContext();
  const dispatch = useDispatch()
  const { t } = useTranslation()
  return (
    <div className="col-md-12 col-sm-12 d-flex justify-content-end">
      <div className="d-flex align-items-center">{ribbons}</div>

      {isAuthenticated() && (
        <div className="d-flex align-items-center logout-link me-3" onClick={async () => {
          //@ts-ignore
          await dispatch(logout(t("frontend.account.logout_success"), t("frontend.account.logout_failure")))
        }}>
          Logout
        </div>
      )
      }

      <SocialLinks color={theme.headerBarTextColour?.trim()} prefix="" />

      {/* Not required for stage 1 */}
      {/* <div className="d-flex align-items-center">
        <LanguagePicker/>
      </div> */}

      <div className="text-black d-flex align-items-center" style={{ color: theme.headerBarTextColour?.trim() }}>
        {theme.headerStyleOption !== "one" &&
          theme.headerStyleOption !== "four" &&
          siteSetting.sitePurpose !== "Non-Transactional" &&
          isAuthenticated() &&
          (
            <div className="ms-3 d-none d-lg-block">
              <MiniCart color={theme.headerBarTextColour?.trim()} />
            </div>
          )}
      </div>
    </div>
  );
};
