import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { SlatwalApiService } from '@ultracommerce/react-storefront/global';
import { useElementContext } from '@ultracommerce/react-storefront/global';

const ForgotPassword = () => {
  const {
    CommonModule: { SWForm, SWInput, PromptLayout },
  } = useElementContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      emailAddress: "",
    },
    onSubmit: (values) => {
      SlatwalApiService.account.forgotPassword(values).then((response) => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) {
          toast.error("You may not reset your password while your account is locked, please contact your administrator.");
          return;
        }
        if (response.isSuccess()) {
          toast.success("Success");
          setTimeout(() => {
            navigate(`/my-account/login`);
          }, 2000);
        } else {
          toast.error("Failure");
        }
      });
    },
  });
  return (
    <PromptLayout>
      <SWForm formik={formik} title="Forgot Password" primaryButtontext="Send Me Reset Email">
        <SWInput required={true} formik={formik} token="emailAddress" label="Email Address" type="email" />
        <Link className="link" to="/my-account/login">
          {t("frontend.account.back_to_login")}
        </Link>
      </SWForm>
    </PromptLayout>
  );
};
//
export { ForgotPassword };