import { useElementContext } from "@ultracommerce/react-storefront/global";

export interface CallToActionBtnProps {
  urlTarget: string;
  linkUrl: string;
  linkLabel: string;
}

const CallToActionBtn = ({ urlTarget, linkLabel, linkUrl, componentLayout}:any) => {
  const {
    CommonModule: { ExternalLink },
  } = useElementContext();
  
  return (
    <div className={`${componentLayout === 'Half Width' && 'col-6'}`}>
      {linkUrl ? (
        <ExternalLink
          linkUrl={linkUrl}
          className={`${componentLayout === 'Half Width' && 'w-100 d-flex justify-content-between py-3 fs-5 fw-bold text-start'} btn btn-primary rounded-3 `}
          target={urlTarget}
          {...(urlTarget === "_blank" ? { rel: "noreferrer noopener" } : {})}
        >
          <p className="pb-0 mb-0">{linkLabel}</p>
          {componentLayout === 'Half Width' &&
          <p className="pb-0 mb-0">{'>'}</p>
          }
        </ExternalLink>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CallToActionBtn;
