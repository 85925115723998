export interface LocationAddressProps {
  address: string;
  city: string;
  postcode: string;
  state: string;
  country?: string;
}

export const LocationAddress = ({ address, city, postcode, state, country }: LocationAddressProps) => {
  const toTitleCase = (str: string) =>
    str?.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  if (address?.trim()?.length < 1) return null;
  return (
    <div className="card-text">
      <i className="bi bi-map" />
      <span style={{ paddingLeft: "10px" }} className="card-link">
        {[toTitleCase(address), toTitleCase(city), state, toTitleCase(postcode)].filter((a) => a).join(", ")}
      </span>
      <br />
      <span>{country}</span>
    </div>
  );
};
