import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormatDateTime, useOrderHistoryList } from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import ContentLoader from "react-content-loader";
import { useCustomFormatCurrency } from "../../../hooks/useFormatCurrency";

const Loader = () => (
  <ContentLoader viewBox="0 0 500 165">
    {Array.apply(null, new Array(10)).map((_, i) => (
      <rect x="0" y={24 * i} rx="4" ry="4" width="100%" height="20" />
    ))}
  </ContentLoader>
);

const OrderStatus = ({ type = "info", text }) => {
  return <span className={`badge bg-${type} m-0`}>{text}</span>;
};

const OrderListItem = (props) => {
  const [formatCurrency] = useCustomFormatCurrency({});
  const [formateDate] = useFormatDateTime();

  const { currencyCode, orderPayments_purchaseOrderNumber, orderID, orderOpenDateTime, orderStatusType_typeName, calculatedTotal } = props;
  return (
    <tr>
      <th>
        {orderID && (
          orderPayments_purchaseOrderNumber ? <Link className="nav-link-style font-weight-medium font-size-sm" to={`/my-account/orders/${orderID}`}>
            {orderPayments_purchaseOrderNumber}
          </Link> : '--'
        )}
        <br />
      </th>
      <td>{formateDate(orderOpenDateTime)}</td>
      <td>
        <OrderStatus text={orderStatusType_typeName} />
      </td>
      <td>{formatCurrency(calculatedTotal,currencyCode)}</td>
    </tr>
  );
};

const OrderHistoryList = () => {
  const { t } = useTranslation();
  const {
    CommonModule: { ListingPagination },
  } = useElementContext();
  const { search, orders, totalPages, currentPage } = useOrderHistoryList({});

  return (
    <>
      <div className="table-responsive font-size-md mt-5">
        <table className="table table-striped table-bordered ">
          <thead>
            <tr>
              <th>PO Number</th>
              <th>{t("frontend.account.order.date")}</th>
              <th>{t("frontend.account.order.status")}</th>
              <th> {t("frontend.account.order.total")}</th>
            </tr>
          </thead>
          <tbody>
            {orders.isLoaded &&
              orders.data.ordersOnAccount?.map((order, index) => {
                return <OrderListItem key={index} {...order} />;
              })}
          </tbody>
        </table>
        {orders.isFetching && <Loader />}
      </div>

      <ListingPagination
        recordsCount={orders.data.records}
        currentPage={currentPage}
        totalPages={totalPages}
        setPage={search}
      />
    </>
  );
};

const AccountOrderHistory = ({ crumbs, title, orders }) => {
  const { t } = useTranslation();
  const {
    CommonModule: { AccountLayout },
  } = useElementContext();

  return (
    <AccountLayout title={t("frontend.account.account_order_history")}>
      <OrderHistoryList orders={orders} />
    </AccountLayout>
  );
};

export { AccountOrderHistory };
