import { Location } from "@ultracommerce/react-storefront/global/src/interface/Location";
import { LocationDetail } from "./LocationDetail";

export interface WhereToBuyLocationCardProps extends Location {
  onLocationClicked: (addressLatitude: string, addresslongitude: string) => void;
  PhysicalCountry?: string;
  // CustomerName: (addressLatitude: string, addresslongitude: string) => void;
}

export const WhereToBuyLocationCard = (props: WhereToBuyLocationCardProps) => {
  const {
    CustomerName,
    AddressLatitude,
    AddressLongitude,
    PhysicalAddress1,
    PhysicalCity,
    PhysicalPostcode,
    PhysicalState,
    PhysicalCountry,
    email,
    Description,
    onLocationClicked,
  } = props;
  return (
    <div
      className="card card-body h-100 locationCard"
      onClick={() => {
        onLocationClicked(AddressLatitude, AddressLongitude);
      }}
    >
      <h4 className="mb-4 font-weight-bold fs-5 pt-0 card-title text-uppercase">{CustomerName}</h4>
      <div
        className="card-subtitle mb-2 text-black"
        dangerouslySetInnerHTML={{
          __html: Description || "",
        }}
      />
      <LocationDetail
        address={PhysicalAddress1}
        city={PhysicalCity}
        postcode={PhysicalPostcode}
        state={PhysicalState}
        email={email}
        country={PhysicalCountry}
      />
    </div>
  );
};
