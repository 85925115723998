import { Dispatch, SetStateAction } from "react";
import { useDeepCompareEffect } from "react-use";
import isEqual from "fast-deep-equal";

export interface ProductModifier {
  key: string;
  required: boolean;
  title: string;
  options: ProductModifierOption[];
}

export interface ProductModifierOption {
  title: string;
  modifier: string;
  code: string;
  isHiddenFlag?: boolean;
  stepQty?: number;
}

export interface ProductModifiersProps {
  modifiers: ProductModifier[];
  skuConfiguration: { [key: string]: string };
  stepQuantity?: any;
  setSkuConfiguration: Dispatch<
    SetStateAction<ProductModifiersProps["skuConfiguration"]>
  >;
}

export const ProductModifiers = ({
  modifiers,
  skuConfiguration,
  setSkuConfiguration,
}: ProductModifiersProps) => {
  useDeepCompareEffect(() => {
    setSkuConfiguration((prevState) => {
      const defaultValue: { [key: string]: string } = {};
      modifiers.forEach((modifier) => {
        const options = modifier.options.filter(
          (value: ProductModifierOption) => !value?.isHiddenFlag
        );
        if (
          prevState[modifier.key] &&
          options.find(({ code }) => code === prevState[modifier.key])
        ) {
          defaultValue[modifier.key] = prevState[modifier.key];
        }
        if (options.length === 1) {
          defaultValue[modifier.key] = options[0].code;
        }
      });
      if (!isEqual(prevState, defaultValue)) return defaultValue;
      return prevState;
    });
  }, [modifiers, setSkuConfiguration]);

  return (
    <div className="col-12">
      <div>
        {modifiers?.map((modifier) => {
          const options = modifier.options.filter(
            (value: ProductModifierOption) => !value?.isHiddenFlag
          );
          if (!options.length) return null;
          return (
            <div className="my-4" key={modifier.key}>
              <label className="h6 pb-1">{modifier.title}</label>
              <select
                className="custom-select rounded-pill"
                value={skuConfiguration[modifier.key]}
                disabled={options.length === 1}
                onChange={(e) => {
                  setSkuConfiguration((prevState) => {
                    const newSkuConfiguration = {
                      ...prevState,
                      [modifier.key]: e.target.value,
                    };
                    return newSkuConfiguration;
                  });
                }}
              >
                {options.length > 1 && (
                  <option value="">-- Choose Configuration Setting --</option>
                )}
                {options.map((value: ProductModifierOption) => (
                  <option key={value.code} value={value.code}>
                    {value.title}
                  </option>
                ))}
              </select>
            </div>
          );
        })}
      </div>
    </div>
  );
};
