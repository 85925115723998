import { OrderItem } from "@ultracommerce/react-storefront/global/src/interface/Cart";
import {
  axios,
  getErrorMessage,
  getSdkURL,
  useElementContext,
} from "@ultracommerce/react-storefront/global";
import { useState } from "react";
import { toast } from "react-toastify";
import { calMinDeliveryDate } from "../utils";
import { useTranslation } from "react-i18next";

export const useUpdatedRequestDeliveryDate = ({
  orderItem,
  orderID,
}: {
  orderID: string;
  orderItem: OrderItem & { effectiveLeadTime: number };
}) => {
  const { t } = useTranslation();
  const {
    CommonModule: { Overlay },
  } = useElementContext();
  const [isSaveLoading, setSaveLoading] = useState(false);
  const { minDeliveryDate, leadTime } = calMinDeliveryDate(
    orderItem.effectiveLeadTime
  );
  const [orderItemRequestedDeliveryDate, setOrderItemRequestedDeliveryDate] =
    // @ts-ignore
    useState(orderItem?.requestedDeliveryDate);
  const [localRequestedDate, setLocalRequestedDate] = useState(
    orderItemRequestedDeliveryDate
  );

  const handleChange = (e: any) => {
    setLocalRequestedDate(e.target.value);
  };

  const saveRequestedDeliveryDate = (e: any) => {
    e.preventDefault();
    setSaveLoading(true);
    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/updateRequestDeliveryDate`,
      data: {
        returnQuote: true,
        isQuote: true,
        orderItem: {
          orderItemID: orderItem.orderItemID,
          requestedDeliveryDate: localRequestedDate,
        },
        orderID,
      },
    }).then((response) => {
      if (!response) {
        toast.error(t("frontend.product.review.errorMessage"));
      } else if (response?.data && response?.data.errors) {
        if (typeof response?.data.errors === "string")
          toast.error(response.data.errors);
        toast.error(getErrorMessage(response.data.errors));
      } else {
        toast.success("Quote updated successfully");
        setOrderItemRequestedDeliveryDate(localRequestedDate);
      }
      setSaveLoading(false);
    });
  };

  const renderInput = () => {
    return (
      <Overlay
        active={isSaveLoading}
        spinner
        styles={{ overlay: (base: any) => ({ ...base , width: '200px'}) }}
      >
        <input
          type="date"
          value={localRequestedDate}
          className="form-control"
          onChange={(e) => handleChange(e)}
          style={{ width: "200px" }}
          min={minDeliveryDate}
          required
        />
      </Overlay>
    );
  };

  const renderActionButton = () => {
    if (
      localRequestedDate !== orderItemRequestedDeliveryDate &&
      !isSaveLoading
    ) {
      return (
        <span className="d-flex">
          <input
            className="text-primary text-underline linkit pe-2 border-0 bg-transparent"
            type="submit"
            value="Save"
          />
          <input
            className="text-primary text-underline linkit pe-2 border-0 bg-transparent"
            type="button"
            value="Cancel"
            onClick={() => {
              // @ts-ignore
              setLocalRequestedDate(orderItemRequestedDeliveryDate);
            }}
          />
        </span>
      );
    }
    return null;
  };

  return {
    data: {
      leadTime,
      isSaveLoading,
      orderItemRequestedDeliveryDate,
      localRequestedDate,
    },
    actions: {
      setSaveLoading,
      setOrderItemRequestedDeliveryDate,
      setLocalRequestedDate,
      handleChange,
      saveRequestedDeliveryDate,
    },
    render: { renderInput, renderActionButton },
  };
};
