import { useEffect } from "react";
import { useGetOrderDetails } from  "@ultracommerce/react-storefront/global";
import { useElementContext } from  "@ultracommerce/react-storefront/global";
import { OrderDetails } from  "./OrderDetails";

const AccountOrderDetail = (props ) => {
  const orderID = props.path;
  let [order, setRequest, formatOrderDetails] = useGetOrderDetails();
  const {
    CommonModule: { AccountLayout, OrderFulfilments, OrderToolbar, Spinner },
  } = useElementContext();
  useEffect(() => {
    let didCancel = false;
    if (!order.isFetching && !order.isLoaded && !didCancel) {
      setRequest({
        ...order,
        isFetching: true,
        isLoaded: false,
        params: { orderID },
        makeRequest: true,
      });
    }
    return () => {
      didCancel = true;
    };
  }, [order, orderID, setRequest]);
  let orderFulfillmentGroups = formatOrderDetails(order.data);
  if (!order.isLoaded )
    return (
      <div className="container">
        <Spinner />
      </div>
    );

  return (
    <AccountLayout title={`Order: ${(order.isLoaded && order.data.orderInfo?.at(0).orderNumber) || ""}` }>
      {order.isLoaded && (
        <OrderToolbar delivered={order.data.orderInfo?.at(0)} orderPayments={order.data.orderPayments?.at(0)} />
      )}  
    
      {order.isLoaded && <OrderFulfilments orderPayments={order.data.orderPayments?.at(0)} delivered={order.data.orderInfo?.at(0)} fulfilments={orderFulfillmentGroups} files={order.data?.files} />}

      {order.isLoaded && (
        <OrderDetails
          orderInfo={order.data.orderInfo?.at(0)}
          orderFulfillments={orderFulfillmentGroups}
          orderPayments={order.data.orderPayments?.at(0)}
        />
      )}
    </AccountLayout>
  );
};
export { AccountOrderDetail };
