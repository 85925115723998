import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useFormatDateTime } from "@ultracommerce/react-storefront/global";

const OrderToolbar = ({
  delivered,
  orderPayments,
  numberText = "frontend.order.OrderNo",
  calculatedGuestAccountFlag,
  orderInfo,
}) => {
  const { t } = useTranslation();
  const [formateDate] = useFormatDateTime();

  return (
    <div className="row pe-0">
      <div
        className="d-grid"
        style={{ gap: "1rem", gridTemplateColumns: "1fr 1fr 1fr" }}
      >
        {orderPayments?.purchaseOrderNumber && (
          <div className="mt-3">
            <h5 className="py-1 m-0">
              PO Number:
              {orderPayments?.purchaseOrderNumber ?? "Not Provided"}
            </h5>
          </div>
        )}
        {delivered?.freightCarrier && (
          <div className="mt-3">
            <h5 className="py-1 m-0">
              <strong>Freight Carrier: &nbsp;</strong>
              {delivered?.freightCarrier}
            </h5>
          </div>
        )}

        {delivered?.specialInstructions && (
          <div className="mt-3">
            <h5 className="py-1 mb-1 m-0">
              <strong>Special Instructions: &nbsp;</strong>
              {delivered?.specialInstructions}
            </h5>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between align-items-center pt-2 pe-0">
        <div className="col-sm-6 px-0">
          <div className="text-muted">
            {t("frontend.order.statusText")}{" "}
            <span className="badge bg-info m-0 p-2 ml-2">
              {delivered.orderStatusType_typeName}
            </span>
          </div>
        </div>
        <div className="col-sm-6 pe-0 d-flex justify-content-end">
          <button
            className="btn btn-outline-secondary w-25 no-print p-2 d-none d-lg-block"
            onClick={() => window.print()}
          >
            <i className="bi bi-printer mr-2"></i> {t("frontend.order.print")}
          </button>
          {delivered.orderTemplate_orderTemplateID && (
            <div className="text-align-right mt-4 justify-content-end">
              <span>
                {t("frontend.account.order.history.orderFromTemplate")}
              </span>
              <Link
                to={`/my-account/subscription-orders/${delivered.orderTemplate_orderTemplateID}`}
              >
                {t("frontend.account.order.history.orderFromTemplateClickHere")}
              </Link>
            </div>
          )}
        </div>

        {calculatedGuestAccountFlag && (
          <div className="row text-sm">
            <div className="col-12">
              <Link to="/checkout/createGuestAccount">
                {t("frontend.cart.create.account")}
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className="col-sm-12 col-lg-12 d-flex align-items-center mb-2">
        <h6 className="h6 mb-0 pe-2">
          {t("frontend.account.order.datePlaced")}
        </h6>
        <p className="mb-0">{formateDate(orderInfo?.orderOpenDateTime)}</p>
      </div>
    </div>
  );
};

export { OrderToolbar };
