import { useTranslation } from "react-i18next";
import { ProductAttributesModel } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductAttributes/ProductAttributes.componentModel";
import { booleanToString, isBoolean } from "@ultracommerce/react-storefront/global";

const ProductAttributesView = ({
  componentData: { product, filteredAttributeSets },
  downloadFile,
}: {
  componentData: ProductAttributesModel;
  downloadFile: ({ fileID }: { fileID: string }) => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="accordion" id="productPanelAccordion">
        {(filteredAttributeSets || []).sort((a, b) => a.sortOrder - b.sortOrder).map((set) => {
          return (
            <div key={set.attributeSetCode} className="accordion-item">
              <h2 className="accordion-header" id={set.attributeSetCode}>
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#panel${set.attributeSetCode}`}
                  aria-expanded="false"
                  aria-controls={`panel${set.attributeSetCode}`}
                >
                  {set.attributeSetName}
                </button>
              </h2>
              <div
                id={`panel${set.attributeSetCode}`}
                className="accordion-collapse collapse"
                aria-labelledby={set.attributeSetCode}
              >
                <div className="accordion-body">
                  <div className="row" style={{ fontSize: 15, letterSpacing: 0.3 }}>
                    {set?.attributes?.filter((attribute) => attribute.attributeCode !== 'technicalDataUS' && attribute.attributeCode !== 'technicalDataMetric').map(({ attributeName, attributeCode }, index) => {
                      if (!isBoolean(product[attributeCode]) && product[attributeCode]?.trim()?.length === 0)
                        return null;
                      return (
                        <div key={attributeCode}>
                          <div className="row">
                            <div
                              className="col-12 text-muted"
                              dangerouslySetInnerHTML={{
                                __html: isBoolean(product[attributeCode])
                                  ? booleanToString(product[attributeCode])
                                  : product[attributeCode],
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                    {set?.attributes.length > 1 &&
                      <div className="Tabs shadow container ">
                        <ul className="nav nav-tabs nav-justified border-0" id="horizontalTabsHeading-" role="tablist">

                          <li className="nav-item" role="presentation" style={{ background: 'rgb(248, 249, 250) !important', borderColor: 'rgb(248, 249, 250) !important' }}><button className="nav-link active" id="Metric-tab" data-bs-toggle="tab" data-bs-target="#Metric" type="button" role="tab" aria-controls="Metric" aria-selected="true">Metric Units</button></li>
                          <li className="nav-item" role="presentation" style={{ background: 'rgb(248, 249, 250) !important', borderColor: 'rgb(248, 249, 250) !important' }}><button className="nav-link " id="U.S.Units-tab" data-bs-toggle="tab" data-bs-target="#U.S.Units" type="button" role="tab" aria-controls="U.S.Units" aria-selected="true" style={{ backgroundColor: 'rgb(248, 249, 250) !important', borderColor: 'rgb(248, 249, 250) !important', color: 'rgb(51, 51, 51) !important' }}>U.S. Units</button></li>

                          <div className="tab-content vertical-tab-border p-2 p-md-5" id="horizontalTabsPanels-">
                            <div className="tab-pane fade show active" id="Metric" role="tabpanel" aria-labelledby="Metric-tab">
                              <div className="content-body">
                                <div
                                  className="col-12 text-muted"
                                  dangerouslySetInnerHTML={{
                                    __html: isBoolean(product['technicalDataMetric'])
                                      ? booleanToString(product['technicalDataMetric'])
                                      : product['technicalDataMetric'],
                                  }}
                                />
                              </div>
                            </div>
                            <div className="tab-pane fade " id="U.S.Units" role="tabpanel" aria-labelledby="U.S.Units-tab">
                              <div className="content-body">
                                <div
                                  className="col-12 text-muted"
                                  dangerouslySetInnerHTML={{
                                    __html: isBoolean(product['technicalDataUS'])
                                      ? booleanToString(product['technicalDataUS'])
                                      : product['technicalDataUS'],
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </ul>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div >
          );
        })}

        {
          product.files.length > 0 && (
            <div className="accordion-item">
              <h2 className="accordion-header" id="filesHeader">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#filesPanel"
                  aria-expanded="false"
                  aria-controls="files"
                >
                  {t("frontend.product.files.heading")}
                </button>
              </h2>

              <div id="filesPanel" className="accordion-collapse collapse" aria-labelledby="files">
                <div className="accordion-body striped">
                  <table className="table table-striped align-middle">
                    <tbody>
                      {product.files.map(({ fileName, fileType, fileID }) => {
                        return (
                          <tr key={fileID}>
                            <td>
                              <button
                                type="button"
                                className="btn btn-link text-muted p-1"
                                onClick={(e) => {
                                  e.preventDefault();
                                  downloadFile({ fileID });
                                }}
                              >
                                {fileName}
                              </button>
                            </td>
                            <td>
                              {fileType?.toLowerCase() === "pdf" && (
                                <i
                                  style={{ color: "#b0aeae", cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    downloadFile({ fileID });
                                  }}
                                  className=" float-end fs-2 mb-3 bi bi-file-earmark-pdf-fill"
                                />
                              )}
                              {fileType?.toLowerCase() !== "pdf" && (
                                <i
                                  style={{ color: "#b0aeae", cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    downloadFile({ fileID });
                                  }}
                                  className=" float-end fs-2 mb-3 bi bi-file-earmark-fill"
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        }

      </div>
    </>
  );
};

export { ProductAttributesView };
