import { Link } from "react-router-dom";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useMemo } from "react";
import { FooterContentProps } from "@ultracommerce/react-storefront/global/src/components/Footer/FooterContent";

export const FooterStyleOne = ({ logo, theme, ribbonMenu, contentStack, formElement }: FooterContentProps) => {
  const {
    CommonModule: { ContentStackMenu },
  } = useElementContext();

  const filteredContentStack = useMemo(
    () => contentStack?.filter((child) => !["Footer Shop Menu"].includes(child.props.el.contentElementName)),
    [contentStack],
  );

  return (
    <>
      <div className="container d-flex justify-s-b align-start footer-container flex-wrap p-3">
        <div className="row col-12 pb-0 footerTopRow">
            {theme?.footerLogoTarget !== "None" && (
              <Link className="col-md-3 col-sm-12 d-flex justify-content-start" to="/">
                <span className="navbar-brand d-block">
                  <img
                    src={`${logo?.trim()}`}
                    alt=""
                    className="img-fluid navbar-brandLogo"
                    style={{
                      maxHeight: "60px",
                      minWidth: "150px",
                      marginBottom: "1em",
                    }}
                  />
                </span>
              </Link>
            )}
            
            <div className="footerMenu col-md-6 col-sm-12 d-flex align-items-center">
              <ContentStackMenu contentStack={filteredContentStack} />
              </div>
              <div className="col-md-3 col-sm-12 pt-0 d-flex justify-content-md-end justify-content-sm-start  align-items-center" dangerouslySetInnerHTML={{ __html: theme.FooterAddress }} />
            </div>
          </div>


      <div className="container footer-ribbon justify-content-between fw-bold flex-wrap">
        {ribbonMenu}
         {theme.copyRight && <p className="m-0 me-2 align-self-center fw-normal">{theme.copyRight}</p>}
      </div>
    </>
  );
};
