import React, { useRef, useEffect, Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getProductRoute, isAuthenticated, useElementContext } from "@ultracommerce/react-storefront/global";
import { useSelector } from "react-redux";
import { SearchBarModel } from "./SearchBar.componentModel";
import { ProductModifiersModal, ProductModifiersModalProps } from "../../../modules/Product/Controls/ProductModifiersModal";
import { AddProductToQuoteModalProps } from "../../../modules/Product/Components/AddProductToQuoteModal/AddProductToQuoteModal.componentModel";

export const SearchBarView = ({
  componentData: {
    opened,
    searchBoxPlaceholder,
    searchTerm,
    searched,
    suggestions,
    suggestionTypeMapping,
    customStyleClasses,
    onClick,
    modifierModal,
    quoteModal,
    modelSearchSearched
  },
  setOpened,
  makeSearch,
  setSearchTerm,
  setModifierModal,
  setQuoteModal
}: {
  componentData: SearchBarModel & {
    modifierModal: ProductModifiersModalProps | false;
    quoteModal: AddProductToQuoteModalProps | false;
    modelSearchSearched: any;
  };
  setOpened: (open: boolean) => void;
  makeSearch: (searchValue: string) => void;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  setModifierModal: Dispatch<SetStateAction<false | ProductModifiersModalProps>>;
  setQuoteModal: Dispatch<SetStateAction<false | AddProductToQuoteModalProps>>;
}) => {
  const { ProductModule } = useElementContext()
  const { t } = useTranslation();
  const productRouting = useSelector(getProductRoute);
  const wrapperRef = useRef<HTMLFormElement>(null);
  const textInput = useRef<HTMLInputElement>(null);
  const { addressZoneName } = useSelector((state : any) => state.userReducer);
  useEffect(() => {
    if (!opened) return;
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setOpened(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const modelSearchProduct = modelSearchSearched?.items?.find(({metadata}:any)=> metadata?.skus.find(({ skuCode }:any) => skuCode === searchTerm.slice(0, 3)))?.metadata;
  const { modelCodeSuggestions = [], modelCodeMatches = [], modelSearchSku } = useMemo(() => {
    if (!modelSearchProduct) return {};
    const product = modelSearchProduct;
    if(product) product.modifiers = product.modifiers || (addressZoneName === "US" ? product?.modifiersUS : product?.modifiersEU) || [];
    const sku =
      product.skus.find(
        ({ skuCode }: any) => skuCode === searchTerm.slice(0, 3)
      ) || product.skus[0];
    let modelCodeSuggestions: string[] = [
      sku.skuCode,
      sku.skuCode,
      sku.skuCode,
      sku.skuCode,
      sku.skuCode,
    ]; // set up 5 suggestions
    const modelCodeMatches: {
      key: string;
      title: string;
      code: string;
      codeTitle: string;
    }[] = [];
    const modelCodeMatchesCount = product.modifiers?.reduce(
      (acc: any, modifier: any, idx: number) => {
        if(acc !== idx) return acc;
        const sectionOffset = product.modifiers
          .slice(0, idx)
          .reduce((acc: any, mod: any) => {
            acc = acc + mod.options[0]?.code.length;
            return acc;
          }, 3);
        const code = searchTerm
          .slice(sectionOffset, sectionOffset + modifier.options[0].code.length)
          .toLowerCase();
        const selectedCode = modifier.options.find(
          (option: any) => {
            return !option.isHiddenFlag &&  option.code.toLowerCase() === code;
          }
        );

        if (selectedCode) {
          acc++;
          modelCodeMatches.push({
            key: modifier.key,
            title: modifier.title,
            code: selectedCode.code,
            codeTitle: selectedCode.title,
          });
          modelCodeSuggestions.forEach((suggest, suggestIdx) => {
            modelCodeSuggestions[suggestIdx] = suggest + selectedCode.code;
          });
        } else if (code.length) {
          const matchesOptions = modifier.options.filter((option: any) =>
            option.code.toLowerCase().startsWith(code) && !option.isHiddenFlag
          );
          if (matchesOptions.length) {
            acc++;
            modelCodeSuggestions.forEach((suggest, suggestIdx) => {
              modelCodeSuggestions[suggestIdx] =
                suggest +
                matchesOptions[suggestIdx % matchesOptions.length].code;
            });
          }
        }
        return acc;
      },
      0
    ) || 0;
    product.modifiers.slice(modelCodeMatchesCount).forEach((modifier: any) => {
      const options = modifier.options.filter(({ isHiddenFlag }: any) => !isHiddenFlag);
      modelCodeSuggestions.forEach((suggest, suggestIdx) => {
        const part = options[suggestIdx % options.length];
        modelCodeSuggestions[suggestIdx] = suggest + (part?.code || "");
      });
    });
    // @ts-ignore
    modelCodeSuggestions = [...new Set(modelCodeSuggestions)];

    return { modelCodeSuggestions, modelCodeMatches, modelSearchSku: sku };
  }, [addressZoneName, modelSearchProduct, searchTerm]);

  const onFullModelCodeSaerch = () => {
    if(!!modelSearchProduct && !!modelCodeMatches?.length && modelCodeMatches?.length === modelSearchProduct?.modifiers?.length) {
      if(!isAuthenticated()) {
        setQuoteModal({ setQuoteModal: ()=> { setQuoteModal(false) } })
        return true
      }
      const skuConfiguration = modelCodeMatches.reduce((acc,cur)=> {
        acc[cur.key] = cur.code;
        return acc;
      }, {} as {[key:string]:string})
      setModifierModal({ product: modelSearchProduct, defaultSkuId: modelSearchSku.skuID, startingSkuConfiguration:skuConfiguration, setShow: setModifierModal as (open:boolean) => void })
      return true;
    }
    return false
  }
  return (
    <form ref={wrapperRef} className={`mb-1 ${customStyleClasses}`}>
      <div className="input-group input-group-lg rounded-pill flex-nowrap searchbar-wrapper">
        <input
          className="form-control appended-form-control rounded-pill"
          type="text"
          ref={textInput}
          value={searchTerm}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setOpened(false);
              if(!onFullModelCodeSaerch()) { makeSearch(e.currentTarget.value); }
            }
          }}
          onChange={(e) => {
            e.preventDefault();
            setSearchTerm(e.target.value);
          }}
          onFocus={() => setOpened(true)}
          placeholder={searchBoxPlaceholder}
          required
        />

        {opened && searchTerm && (
          <div
            className={`p-2 predictive-search-container shadow d-flex ${!searched?.items?.length || !suggestions?.length ? "predictive-search-container--column" : ""
              }`}
            style={{
              borderRadius: "0 0 0 0",
            }}
          >
            <div>
              <div className="d-table">
                <Link
                  className="d-table-row search-prediction search-text-span"
                  to={`/shop?keyword=${searchTerm}`}
                  onClick={(e) => {
                    setOpened(false);
                    e.preventDefault();
                    makeSearch(searchTerm);
                  }}
                >
                  <span className="d-table-cell p-1 small text-nowrap">Search</span>
                  <span className="d-table-cell small">:</span>
                  <span className="d-table-cell p-1 w-100 fw-bold text-dark spelling_suggestion">{searchTerm}</span>
                </Link>
                {suggestions?.map((suggest, index) => {
                  return (
                    <Link
                      key={index}
                      className="d-table-row search-prediction search-text-span"
                      to={suggest.link}
                      onClick={(e) => {
                        setOpened(false);
                        if (!suggestionTypeMapping[suggest.type]) {
                          e.preventDefault();
                          makeSearch(suggest.text);
                        }
                      }}
                    >
                      <span className="d-table-cell p-1 small text-nowrap">
                        {suggestionTypeMapping[suggest.type] || "Search"}
                      </span>
                      <span className="d-table-cell small">:</span>
                      <span className="d-table-cell p-1 w-100 fw-bold text-dark spelling_suggestion">
                        {suggest.text}
                      </span>
                    </Link>
                  );
                })}
              </div>
            </div>

            {(!!modelSearchProduct || searched?.items?.length > 0) && (
              <div className="ps-2">
                <div className="products-results-container px-2">
                  <div className="products-header py-2">
                    <span className="brand-blue fw-bold small">{t("frontend.product.products").toUpperCase()}</span>
                  </div>
                </div>
                <div className="d-flex flex-column product-search-columns">
                  {(!!modelSearchProduct ? [{ metadata: modelSearchProduct }] : searched.items)
                  .map((item: any, idx: number, { length }: { length: number }) => {
                    const product = item.metadata;
                    if(product) product.modifiers = product.modifiers || (addressZoneName === "US" ? product?.modifiersUS : product?.modifiersEU) || [];
                    return (
                      <React.Fragment key={idx}>
                        <Link onClick={(e) => {
                          setOpened(false);
                          if(onFullModelCodeSaerch()) e.preventDefault()
                          if (onClick) {
                            e.preventDefault();
                            onClick({ product, searchTerm })
                          }
                        }}
                          to={`/${productRouting}/${product.urlTitle}?skuid=${product.skuID}`}
                        >
                          <div className="search-product">
                            <img src={product.thumbnail} alt={product?.urlTitle}/>
                            <div className="search-detail">
                              <div className="search-product-name">{product?.title.replace(/â€“/g, '-')}</div>
                              {!!modelSearchProduct && modelCodeMatches.length < product.modifiers.length && (
                                <>
                                  <div className="search-product-code">MODEL CODES:</div>
                                  <ul className="search-suggestions">
                                    {modelCodeSuggestions.map((suggestion: string, idx: number) => {
                                      let matchLength = suggestion.split('').findIndex((char,i)=> char !== searchTerm[i]);
                                      if(matchLength === -1) matchLength = suggestion.length;
                                      return (
                                      <li className="search-suggestion" key={idx}>
                                        <strong>{suggestion.slice(0, matchLength)}</strong>{suggestion.slice(matchLength)}
                                      </li>
                                    )})}
                                  </ul>
                                </>
                              )}
                              {!!modelSearchProduct && modelCodeMatches.length === product.modifiers.length && (
                                <ul className="search-product-modifiers">
                                  {modelCodeMatches.map((modifier: any, idx: number) => (
                                    <li key={idx}>
                                      <span className="product-modifier-title">{modifier.title}: </span>
                                      <span className="product-modifier-value">{modifier.codeTitle}</span>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        </Link>
                        {length - 1 !== idx && <hr className="m-0 category-product-divider" />}
                      </React.Fragment>
                    );
                  })}
                </div>
                <Link
                  to={`/shop?keyword=${searchTerm}`}
                  onClick={() => {
                    setOpened(false);
                  }}
                >
                  <button className="btn btn-secondary brand-navy w-100">See all Results</button>
                </Link>
              </div>
            )}
          </div>
        )}

        <button
          onClick={(e) => {
            e.preventDefault();
            textInput.current?.focus();
          }}
          className="btn mb-0 px-2 search-btn"
          type="button"
        >
          <i className="bi bi-search color-black"></i>
        </button>
      </div>
      {modifierModal && (
        <ProductModifiersModal
          {...modifierModal}
          // @ts-ignore
          addItem={(
            sku: { skuID: any; },
            skuConfiguration: any,
            calculatedData: any,
            materialNumber: any,
            requestedDeliveryDate: any,
            itemCount: any,
          ) => {
            setQuoteModal({ 
              skuID: sku.skuID,
              modelNumber: calculatedData.modelNumber,
              requestedDeliveryDate,
              materialNumber,
              quantity: itemCount,
              setQuoteModal: () => { 
                setQuoteModal(false); 
                setModifierModal(false); 
              }
            })
          }}
        />
      )}
      {quoteModal && <ProductModule.CoreComponents.AddProductToQuoteModal {...quoteModal}/>}
    </form>
  );
};
