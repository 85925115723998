import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  axios,
  useElementContext,
  useAccountOverview,
  getSdkURL,
} from "@ultracommerce/react-storefront/global";

const OverviewDraftOrders = (props) => {
  const { customStyleClasses } = props;
  const { t } = useTranslation();
  const {
    orders: draftOrder,
    pageRecordsShow,
    currentPage,
    setCurrentPage,
  } = useAccountOverview({
    tradeOrderListing: 1,
  });
  const [createOrderModal, setCreateOrderModal] = useState(false);
  const [orderName, setOrderName] = useState("");
  const [isCreatingQuote, setCreatingQuote] = useState(false);
  const navigate = useNavigate();
  const {
    CommonModule: { OverviewOrderList, ListingPagination, Modal },
  } = useElementContext();

  const handleSubmit = () => {
    if (isCreatingQuote) return;
    setCreatingQuote(true);
    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/createQuote`,
      data: { quoteName: orderName },
    })
      .then((response) => {
        if (
          response?.status === 200 &&
          response?.data?.failureActions.length === 0
        ) {
          setCreateOrderModal(false);
          navigate(`/my-account/draftorder/${response?.data.orderID}`);
        }
      })
      .finally(() => {
        setCreatingQuote(false);
      });
  };

  const totalPages = Math.ceil(draftOrder?.data?.records / pageRecordsShow);

  return (
    <div className={`my-3 ${customStyleClasses}`}>
      <div className="d-flex justify-content-between flex-wrap align-items-center">
        <h4>{t("frontend.order.draft_order")}</h4>
        <button
          className="btn btn-primary"
          onClick={() => setCreateOrderModal(true)}
        >
          Start New Order
        </button>
      </div>
      <OverviewOrderList orders={draftOrder} orderType="Draft" />
      <ListingPagination
        recordsCount={draftOrder?.data?.records}
        currentPage={currentPage}
        totalPages={totalPages}
        setPage={setCurrentPage}
      />
      <Modal
        show={createOrderModal}
        setShow={setCreateOrderModal}
        title="Create Order"
        size="xMedium"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          className="text-center"
        >
          <div className="col-12 d-flex mb-2">
            <div className="col-4">
              <label className="form-label">Order Name*</label>
            </div>
            <div className="col-8">
              <input
                type="text"
                className="form-control"
                name="orderName"
                onChange={(e) => setOrderName(e.target.value)}
                value={orderName}
                required
              />
            </div>
          </div>
          <button className="btn btn-primary my-2" type="submit">
            Save
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default OverviewDraftOrders;
