import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getProductTypeRoute, useElementContext, sortProductTypes } from "@ultracommerce/react-storefront/global";
import { ProductTypeListModel } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductTypeList/ProductTypeList.componentModel";

const ProductTypeListView = ({
  componentData: {
    childProductTypes,
    showOnlyDirectChild = false,
    isFetching = true,
    customStyleClasses,
    rangeMetaData,
  },
}: {
  componentData: ProductTypeListModel;
}) => {
  const productTypeRoute = useSelector(getProductTypeRoute);
  const {
    CommonModule: { SWImage, Spinner },
  } = useElementContext();

  if (isFetching)
    return (
      <div className={`container bg-light box-shadow-lg rounded-lg p-5 ${customStyleClasses}`}>
        <Spinner />
      </div>
    );

  if (!childProductTypes.length) return null;
  return (
    <div className={`container pb-4 pb-sm-5 my-2 ${customStyleClasses}`}>
      <div className="row">
        {sortProductTypes(childProductTypes, rangeMetaData).map(
          ({ productTypeID, productTypeName, imageFile, urlTitle }: any) => {
            let customImagePath = "";
            let imageFileName = "";
            if (imageFile !== "" && !imageFile.startsWith("http")) {
              imageFileName = imageFile.split("/").reverse()?.at(0);
              customImagePath = imageFile.split("/").slice(0, -1).join("/") + "/";
            } else {
              imageFileName = imageFile.includes("-thumb.")
                ? imageFile
                : imageFile.substring(0, imageFile.lastIndexOf(".")) +
                  "-thumb" +
                  imageFile.substring(imageFile.lastIndexOf("."));
            }
            const rangeData = rangeMetaData[urlTitle];
            productTypeName = rangeData?.rangeName || productTypeName;
            imageFileName = rangeData?.rangeLogo || imageFileName;

            return (
              <div className="col-12 px-sm-2 px-md-0 my-2" key={productTypeID}>
              <article className="product-item card">
                <div className="row box-shadow-bottom py-3 ">
                  <div className="col-lg-3 col-12 d-flex justify-content-center">
                    <div className="product-thumb d-flex my-auto">
                  <Link
                    to={`/${productTypeRoute}/${urlTitle}`}
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      })
                    }
                    className="d-block overflow-hidden rounded-lg"
                  >
                    <SWImage
                      className="productImage d-block w-100"
                      customPath={customImagePath}
                      src={imageFileName}
                      alt={productTypeName}
                      type="productType"
                    />
                  </Link>
                  </div>
                  </div>

                  <div className="col-lg-6 col-12 d-flex">
                    <div className="product-info p-3">
                      <h4 className="product-title mb-3"><Link
                        to={`/${productTypeRoute}/${urlTitle}`}
                        className="link-button"
                        onClick={() =>
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          })
                        }
                      >
                        {productTypeName}
                      </Link></h4>
                      {!!(rangeData as any)?.shortDescription && <div className="product-desc">{(rangeData as any)?.shortDescription}</div>}
                    </div>
                  </div>

                  <div className="col-lg-3 col-12 px-4 d-flex m-auto justify-content-center">
                    <Link className="btn btn-outline-danger border-radius-0 font-weight-bold" to={`/${productTypeRoute}/${urlTitle}`}>DISCOVER MORE<i className="bi bi-arrow-right ml-3 ps-2"></i></Link>
                  </div>

                </div>
              </article>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export { ProductTypeListView };
