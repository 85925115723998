import { processQueryParameters } from "@ultracommerce/react-storefront/global";
import { PotentialFilter } from "@ultracommerce/react-storefront/global/src/interface/SearchProduct";

import { useEffect, useState } from "react";
import { useLocation } from "react-router";

export interface FilterUnit {
  slug: string;
  unit: string;
}

export interface SelectedUnit {
  [key: string]: string;
}

export const isFacetWithUnit = (filter: PotentialFilter) =>
  filter.name.includes("|unit:");
export const getFacetUnitGroupName = (filter: PotentialFilter) =>
  filter.name.split("|unit:")[0];
export const getFacetUnit = (filter: PotentialFilter) =>
  filter.name.split("|unit:")[1];

const getUnits = (filters: PotentialFilter[], unitKey: string) => {
  return filters.reduce<FilterUnit[]>((acc, filter) => {
    if (isFacetWithUnit(filter) && getFacetUnitGroupName(filter) === unitKey) {
      acc.push({ slug: filter.slug, unit: getFacetUnit(filter) });
    }
    return acc;
  }, []);
};

export const useUnitFilter = (
  filters: PotentialFilter[]
): (PotentialFilter & { units?: FilterUnit[] })[] => {
  const loc = useLocation();

  const [selectedUnit, setSelectedUnit] = useState<SelectedUnit>(() => {
    let initSelectedUnit = {};
    try {
      const unit = localStorage.getItem("facets_unit");
      initSelectedUnit = JSON.parse(unit || "{}");
    } catch {
      initSelectedUnit = {};
    }

    return initSelectedUnit;
  });

  useEffect(() => {
    const params = processQueryParameters(loc.search);
    setSelectedUnit((prevState) => {
      let newSelectedUnit = { ...prevState };
      filters.forEach((filter) => {
        if (isFacetWithUnit(filter) && params[`facet_${filter.slug}`]) {
          newSelectedUnit = {
            ...newSelectedUnit,
            [getFacetUnitGroupName(filter)]: filter.slug,
          };
        }
      });
      return newSelectedUnit;
    });
  }, [filters, loc.search]);

  useEffect(() => {
    localStorage.setItem("facets_unit", JSON.stringify(selectedUnit));
  }, [selectedUnit]);

  return filters.reduce<
    (PotentialFilter & {
      units?: FilterUnit[];
      setSelectedUnit?: typeof setSelectedUnit;
    })[]
  >((acc, filter) => {
    const isUnitFilter = isFacetWithUnit(filter);
    if (!isUnitFilter) {
      acc.push(filter);
      return acc;
    }
    const facetUnitKey = getFacetUnitGroupName(filter);
    const units = getUnits(filters, facetUnitKey);
    const withSelectedUnit =
      units.length > 1 &&
      selectedUnit[facetUnitKey] &&
      units.some((unit) => unit.slug === filter.slug);

    if (
      (withSelectedUnit && selectedUnit[facetUnitKey] === filter.slug) ||
      (!withSelectedUnit && units[0].slug === filter.slug)
    ) {
      acc.push({
        ...filter,
        name: getFacetUnitGroupName(filter),
        units: units.length > 1 ? units : undefined,
        setSelectedUnit,
      });
    }

    return acc;
  }, []);
};
