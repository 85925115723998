import { WhereToBuyLocationProps } from "@ultracommerce/react-storefront/global/src/modules/Location/Components/WhereToBuyLocation/WhereToBuyLocation.componentModel";
import { useComponentData } from "@ultracommerce/react-storefront/global/src/modules/Location/Components/WhereToBuyLocation/WhereToBuyLocation.componentService";
import { WhereToBuyLocationView } from "./WhereToBuyLocation.componentView";

const WhereToBuyLocation = (props: WhereToBuyLocationProps) => {
  const { componentData, componentService } = useComponentData(props);
  return <WhereToBuyLocationView componentData={componentData} {...componentService} />;
};

export { WhereToBuyLocation, type WhereToBuyLocationProps };
