import { useState } from "react";
import { ProductModifier } from "@ultracommerce/react-storefront/global/src/interface/Product";
import View from "./ProductDetailsPage.pageView";

import { useComponentModel } from "@ultracommerce/react-storefront/global/src/pages/ProductDetailsPage/ProductDetailsPage.pageService";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { ProductContextProvider } from "../../contexts/ProductContext";
import { calStepAndMinQuantity, genModelNumber } from "../../utils";

const ProductDetailPage = () => {
  const {
    PageModule: { DynamicPage },
    CommonModule: { PageContentRow },
  } = useElementContext();
  return (
    <DynamicPage>
      {({ renderDynamicContent }: any) => (
        <>
          <ProductContextProvider>
            <PageContentRow>
              <ProductDetailPageView />
            </PageContentRow>
            {renderDynamicContent()}
          </ProductContextProvider>
        </>
      )}
    </DynamicPage>
  );
};

const ProductDetailPageView = () => {
  const { componentData } = useComponentModel();
  const modifiers = (componentData.product as any).modifiers as ProductModifier[];
  const [skuConfiguration, setSkuConfiguration] = useState<{[key: string]: string;}>({});

  if (modifiers?.some((modifier) => modifier.required && !skuConfiguration[modifier.key])) {
    componentData.productForm = undefined;
  }

  if(componentData.productForm) {
    const { minQty, stepQty } = calStepAndMinQuantity(modifiers, skuConfiguration);
    componentData.productForm = {
      ...componentData.productForm,
      //@ts-ignore
      minQty,
      stepQty,
      skuConfiguration,
      modelNumber: genModelNumber(componentData.selectedSKu?.skuCode || "", modifiers, skuConfiguration)
    }
  }
  
  return (
    <View
      {...componentData}
      productModifiers={
        modifiers.length
          ? { modifiers, setSkuConfiguration, skuConfiguration }
          : undefined
      }
    />
  );
};

export default ProductDetailPage;
