import { axios, getSdkURL } from "@ultracommerce/react-storefront/global";

const bulkCalculatePrice = async (skus) => {
  const response = await axios({
    method: "POST",
    withCredentials: true,
    url: `${getSdkURL()}api/scope/bulkCalculateSkuConfigurationPrice`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      skus: skus.map(({ skuID, quantity, modelNumber }) => ({
        skuID: skuID,
        quantity: quantity,
        modelNumber,
      })),
    },
  });
  response.data?.prices?.forEach((price, i) => {
    if (price.modelNumber !== skus[i].modelNumber) {
      console.error(
        `Mismatch model number on price calculation.Index: ${i}. Sending ${skus[i].modelNumber}, getting ${price.modelNumber}`
      );
    }
  });
  return response;
};

const setAllCartOrQuotes = () => {
  return async (dispatch) => {
    var cartAndQuote = [];
    axios({
      method: "GET",
      url: `${getSdkURL()}api/scope/getCartAndDraftQuoteOrders`,
      data: {},
    }).then((response) => {
      if (
        response?.status === 200 &&
        response?.data?.failureActions.length === 0
      ) {
        cartAndQuote = response?.data?.cartQuoteOrders;
        dispatch({ type: "GET_ALL_CART_OR_QUOTE", cartAndQuote });
      }
    });
  };
};

export { bulkCalculatePrice, setAllCartOrQuotes };
