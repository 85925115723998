import { useUtilities } from "@ultracommerce/react-storefront/global";
import { Link } from "react-router-dom";
import { useElementContext } from "@ultracommerce/react-storefront/global";

const ContentBlock = (props) => {
  const {
    CommonModule: { AttributeImage },
  } = useElementContext();
  const {
    stylingCustomClasses = "",
    contentBody = "",
    imagePath,
    linkUrl,
    systemCode,
    contentHeading,
    children,
    style,
    customStyleClasses,
  } = props;
  let { eventHandlerForWSIWYG } = useUtilities();
  return (
    <div className={[`custom-card card bg-light border-0 h-100`, stylingCustomClasses, systemCode, customStyleClasses].join(" ")}>
      <Link
        className="h-100"
        to={linkUrl}
        style={{ textDecoration: "none", color: "#4b566b", cursor: `${linkUrl ? "pointer" : "context-menu"}` }}
      >
        <div
          className={`${
            style === "Content over image" && "column-container"
          } d-flex flex-column card-body position-relative h-100`}
        >
          {imagePath && imagePath.trim().length > 0 && (
            <div
              className={`max-height-img d-flex ${
                style !== "Standard" && "sec-style"
              } w-100 justify-content-center position-relative`}
            >
              <AttributeImage fileName={imagePath} alt={contentHeading} className="mw-100 content-card-img zoom-in" />
              {style === "Content over image" && (
                <div className="sec-style-content position-absolute d-flex flex-column align-self-center justify-self-center start-0 bg-shadow text-white w-100">
                  {contentHeading && (
                    <h3 className="fw-light text-white p-4 mb-0">{contentHeading}</h3>
                  )}
                  {contentBody && (
                    <div
                      onClick={eventHandlerForWSIWYG}
                      className="px-3 small"
                      style={{ textAlign: "justify" }}
                      dangerouslySetInnerHTML={{ __html: contentBody }}
                    />
                  )}
                  {children}
                </div>
              )}
            </div>
          )}

          {style === "Standard" && (
            <>
              <h3 className="mb-3 mt-2 fw-light text-center fs-4">{contentHeading}</h3>
              <div
                onClick={eventHandlerForWSIWYG}
                className="text-center fs-6"
                dangerouslySetInnerHTML={{ __html: contentBody }}
              />
              {children}
            </>
          )}
          {style === "Content Below Image" && (
            <div className="bg-dark text-white" style={{ height: "-webkit-fill-available" }}>
              <h3 className="mb-3 fw-light text-center text-white fs-4">{contentHeading}</h3>
              <div
                onClick={eventHandlerForWSIWYG}
                className="fs-6 px-3 pb-3"
                dangerouslySetInnerHTML={{ __html: contentBody }}
              />
              {children}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};

export { ContentBlock };
