const HomeAboutUs = ({ stats, image, header, subheading }: { stats: any; image: any; header: any; subheading: any; }) => {

  return (
    <section className="section-about container my-lg-100 my-5 px-0">
      <div className="text-center">
        <h2 className="mb-5">{header.aboutIF}</h2>
        <div className="row">
          <div className="col-lg-3 col-12 mb-4 mb-lg-0">
            <img src={image.motorExpertise} alt="Motor expertise"></img>
            <h3 className="text-primary mt-4"><span className="font-size-60">{stats.motorExpertise}<sup className="font-size-36">years</sup></span></h3>
            <h5>{subheading.motorExpertise}</h5>
          </div>
          <div className="col-lg-3 col-12 mb-4 mb-lg-0">
            <img src={image.motorManufactured} alt="Motor manufactured"></img>
            <h3 className="text-primary mt-4"><span className="font-size-60">{stats.motorManufactured}<sup className="font-size-36">+</sup></span></h3>
            <h5>{subheading.motorManufactured}</h5>
          </div>
          <div className="col-lg-3 col-12 mb-4 mb-lg-0">
            <img src={image.patents} alt="Patents"></img>
            <h3 className="text-primary mt-4"><span className="font-size-60">{stats.patents}<sup className="font-size-36">+</sup></span></h3>
            <h5>{subheading.patents}</h5>
          </div>
          <div className="col-lg-3 col-12 mb-4 mb-lg-0">
            <img src={image.continents} alt="Continents"></img>
            <h3 className="text-primary mt-4"><span className="font-size-60">{stats.continents}<sup className="font-size-36"></sup></span></h3>
            <h5>{subheading.continents}</h5>
          </div>
        </div>
      </div>
    </section>
  );
};

export { HomeAboutUs };