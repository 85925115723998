import { useComponentData } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductPrice/ProductPrice.componentService";
import { ProductPriceView } from "./ProductPrice.componentView";

export interface ProductPriceProps {
  salePrice?: number;
  listPrice?: number;
  type?: string;
  showPriceForUserType?: string;
  ignoreDisplayRules?: boolean;
  currencyCode?: string;
}

const ProductPrice = (props: ProductPriceProps) => {
  const { componentData } = useComponentData(props);
  //@ts-ignore
  componentData.currencyCode = props.currencyCode;
  return <ProductPriceView componentData={componentData} />;
};

export { ProductPrice };
