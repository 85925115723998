import { useUtilities } from "@ultracommerce/react-storefront/global"
import { useElementContext } from "@ultracommerce/react-storefront/global";

const ActionBanner = ({
  contentHeading,
  contentSubHeading,
  contentBody = "",
  linkLabel = "",
  linkUrl = "",
  style,
  bootStrapLayoutClass,
  urlTarget,
  ctaButtonType,
  customStyleClasses,
}) => {
  const {
    CommonModule: { CallToActionBtn },
  } = useElementContext();
  let { eventHandlerForWSIWYG } = useUtilities();
  return (
    <div
      className={`py-4 action-banner-sec container ${style.toLowerCase()} ${bootStrapLayoutClass} ${customStyleClasses || ""}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 p-5">
            {!!contentHeading && (
              <header className="section-title">
                <h2 className="text-start fw-bold">{contentHeading}</h2>
                <h5>{contentSubHeading}</h5>
              </header>
            )}
            <div onClick={eventHandlerForWSIWYG} dangerouslySetInnerHTML={{ __html: contentBody }} />
            {!!linkLabel && (
              <CallToActionBtn
                urlTarget={urlTarget}
                linkLabel={linkLabel}
                linkUrl={linkUrl}
                ctaButtonType={ctaButtonType}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ActionBanner };
