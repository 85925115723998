import { LocationAddressProps } from "./LocationAddress";

export interface LocationDetailProps extends LocationAddressProps {
  locationType?: string;
  phoneNo?: string;
  faxNo?: string;
  websiteURL?: string;
  email?: string;
}

export const LocationDetail = ({
  locationType,
  phoneNo,
  faxNo,
  websiteURL,
  email,
  ...addressProps
}: LocationDetailProps) => {
  return (
    <>
      {!!locationType && <h6 className="card-subtitle mb-2 text-muted">{locationType}</h6>}
      {!!phoneNo && (
        <div className="text-uppercase">
          <i className="bi bi-telephone" />
          <span style={{ paddingLeft: "10px" }}>
            <a href={`tel:${phoneNo}`} className="card-link">
              {`${phoneNo}`}
            </a>
          </span>
        </div>
      )}
      {!!email && (
        <div>
          <i className="bi bi-envelope" />
          <span style={{ paddingLeft: "10px" }}>
            <a href={`mailto:${email}`} className="card-link">
              {email}
            </a>
          </span>
        </div>
      )}
      {!!faxNo && (
        <div title="Fax">
          <i className="bi bi-printer " />
          <span style={{ paddingLeft: "10px" }} className="card-link">
            {faxNo}
          </span>
        </div>
      )}
      {!!websiteURL && (
        <div title="WebsiteURL">
          <i className="bi bi-globe " />
          <span style={{ paddingLeft: "10px" }}>
            <a href={websiteURL} className="card-link" target="_new">
              {websiteURL.replace(/(^\w+:|^)\/\//, "")}
            </a>
          </span>
        </div>
      )}
    </>
  );
};
