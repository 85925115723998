import { useRef, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import axios, { CancelTokenSource } from "axios";
import { useSelector } from "react-redux";
import { useDebounce } from "react-use";
import {
  processQueryParameters,
  useServiceContext,
} from "@ultracommerce/react-storefront/global";
import { SearchBarProps, SearchBarModel } from "./SearchBar.componentModel";
import { getRouteMapping } from "@ultracommerce/react-storefront/global";
import { ProductModifiersModalProps } from "../../../modules/Product/Controls/ProductModifiersModal";
import { AddProductToQuoteModalProps } from "../../../modules/Product/Components/AddProductToQuoteModal/AddProductToQuoteModal.componentModel";

export const useComponentData = (props: SearchBarProps) => {
  const {
    redirectToSearchPage = false,
    searchBoxPlaceholder = "",
    searchBoxTypeaheadFlag = false,
    customStyleClasses,
    onClick = null,
  } = props;
  const { ProductService } = useServiceContext();
  const productService = useMemo(() => new ProductService(), [ProductService]);
  const routeMapping: any = useSelector(getRouteMapping);
  const navigate = useNavigate();
  const [modifierModal, setModifierModal] = useState<
    ProductModifiersModalProps | false
  >(false);
  const [quoteModal, setQuoteModal] = useState<AddProductToQuoteModalProps| false>(false);

  const location = useLocation();
  const { keyword } = queryString.parse(location.search);
  const [searched, setSearch] = useState<any>(null);
  const [modelSearchSearched, setModelSearchSearched] = useState<any>(null);

  const [searchTerm, setSearchTerm] = useState((keyword as string) || "");
  let [opened, _setOpened] = useState(false);

  const searchRef = useRef<CancelTokenSource>();
  const modelSearchRef = useRef<CancelTokenSource>();

  useDebounce(
    () => {
      if (opened && searchTerm) predictiveSearch(searchTerm);
    },
    500,
    [searchTerm, opened]
  );

  const modelSearchValue = searchTerm.slice(0, 3)
  useDebounce(
    () => {
      if (opened && modelSearchValue && !redirectToSearchPage) predictiveModelSearch(modelSearchValue);
    },
    500,
    [modelSearchValue, opened]
  );

  const setOpened = (val: boolean) => searchBoxTypeaheadFlag && _setOpened(val);

  const makeSearch = (searchValue: string) => {
    const params = processQueryParameters(location.search);
    const newParams = { ...params, keyword: searchValue } as { [key: string]: string | string[] };
    if (searchValue !== params.keyword && !!searchValue && newParams["sort"]) {
      delete newParams["sort"];
    }
    if (redirectToSearchPage) {
      navigate({
        pathname: "/shop",
        search: queryString.stringify(location.pathname === "/shop" ? newParams : { keyword: searchValue }, {
          arrayFormat: "comma",
        }),
      });
      return;
    }
    navigate({
      search: queryString.stringify(newParams, { arrayFormat: "comma" }),
    });
    setSearchTerm("");
  };

  const predictiveModelSearch = (value: string) => {
    if (modelSearchRef.current) modelSearchRef.current.cancel();

    modelSearchRef.current = axios.CancelToken.source();
    if (value.length !== 3) {
      // setHide(false);
      setModelSearchSearched(null);
      return null;
    }

    productService
      .searchTypeahead(value, "product", { source: modelSearchRef.current })
      .then(({ data }) => {
        setModelSearchSearched(data);
      });
  };


  const predictiveSearch = (value: string) => {
    if (searchRef.current) searchRef.current.cancel();

    searchRef.current = axios.CancelToken.source();
    if (value === "") {
      // setHide(false);
      setSearch(null);
      return null;
    }

    productService
      .searchTypeahead(value, "product", { source: searchRef.current })
      .then(({ data }) => {
        setSearch(data);
      });
  };

  return {
    componentData: {
      ...new SearchBarModel({
        opened,
        searchBoxPlaceholder,
        searchTerm,
        searched,
        routeMapping,
        customStyleClasses,
        onClick,
      }),
      modifierModal,
      quoteModal,
      modelSearchSearched
    },
    componentService: { setOpened, makeSearch, setSearchTerm, setModifierModal, setQuoteModal },
  };
};
