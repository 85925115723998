import React,{ useContext, useState, useEffect } from "react";
import { FormContext } from "../DynamicForm";
// import { formModel_formQuestion } from "../dynamicFormModel.class";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import {getSdkURL, axios} from '@ultracommerce/react-storefront/global'
import {useLocation} from 'react-router-dom'
const FormInputLayout = () => {
  const {
    CommonModule: { FormCol, FormRow },
  } = useElementContext();
  const { layoutInput } = useContext(FormContext);
  const [incoVal, setIncoVal] = useState<any>("")
  return (
    <>
      {layoutInput?.map((rowInputs: any, i: number) => {
        return (
        <React.Fragment key={i}> 
        {
          rowInputs[0]?.heading &&
          <div className="row py-2"><h3 className="fw-bold">{rowInputs[0].heading}</h3></div>
        }
        {
          rowInputs[0]?.subheading &&
          <div className="row py-2"><h5 className="fw-bold text-muted">{rowInputs[0].subheading}</h5></div>
        }
        {!(rowInputs[0]?.conditional && incoVal ==="EXW") && 
        <FormRow key={i}>
          {rowInputs?.map((q: any, idx: number) => {
            return (
              <FormCol key={idx}>
                <FormEL {...q} incoVal={incoVal} setIncoVal={setIncoVal}/>
              </FormCol>
            );
          })}
        </FormRow>
        }
        </React.Fragment>
      )
      }
      )}
    </>
  );
};

export default FormInputLayout;

const FormEL = (props: any) => {
  const {
    CommonModule: {
      FormCheckboxField,
      FormCheckboxGroupField,
      FormChronoField,
      FormFileField,
      FormMultiSelectField,
      FormRadioField,
      FormSelectField,
      FormTextField,
      FormYesNoField,
    },
  } = useElementContext();
  const { attributes, incoVal, setIncoVal,...rest } = props;
  const [locations, setLocations] = useState<any>("")
  
  const {value, setValue, invalid, validationMessage} = rest
  const {pathname} = useLocation()
  useEffect(()=>{
    async function fetchLocationData(){
    
      const locationData = await axios({
      method: "GET",
      withCredentials: true,
      url: `${getSdkURL()}/api/scope/getWarehouseLocations`,
      headers: {
        "Content-Type": "application/json",
      }
    }).then((response: any) => {
      if(response.status === 200){
        return response?.data?.locations;
      }else{
        return response
      }
      
    });
    
    setLocations(locationData)
    }
    if(pathname === '/register' && attributes.attributeCode === 'incoterm'){
      fetchLocationData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  if(attributes.attributeCode === "paymentTerms"){
    return (
        <>
          <label htmlFor={attributes.attributeCode} className={`form-label ${attributes.requiredFlag ? "required" : ""}`}>
            {attributes?.attributeName}
          </label>
          <div className="form-control" style={{backgroundColor: '#dce7f1', color: '#12559A', border: '1px solid #12559A'}}>
            <i className="bi bi-info-circle-fill me-2"></i>
            Pre-pay payments are required until Account Credit Application is approved.
          </div>
        </>
      )
  }
  
  if(attributes.attributeCode === "incoterm"){
    return (
      <div className="d-flex flex-wrap gap-3">
      <fieldset className="d-flex flex-column">
      <div className={`form-label ${attributes.requiredFlag ? "required" : ""}`}>{attributes.attributeName}</div>
      {attributes.options.map((option:any, idx:any) => {
        return (
          <div className="form-check" key={idx}>
            <input
              onChange={() => {
                setIncoVal(option.value) 
                if(option.value !== 'EXW'){
                setValue((response: any) => {
                  return {
                    ...response,
                    [attributes.attributeCode]: option.value,
                  };
                }); 
                }else{
                  setValue((response: any) => {
                  return {
                    ...response,
                    [attributes.attributeCode]: locations[0].locationCode,
                  };
                }); 
                }
              }}
              value={attributes.attributeCode}
              className={"form-check-input" + (invalid ? " is-invalid" : "")}
              type="radio"
              name={attributes.attributeCode + option.name}
              checked={option.value === incoVal}
              id={attributes.attributeCode + option.name}
            />
            <label className="form-check-label" htmlFor={attributes.attributeCode + option.name}>
              {option.name}
            </label>
          </div>
        );
      })}
      {invalid && <div className="invalid-feedback">{validationMessage ?? ""}</div>}
    </fieldset>
    {!['DAP','DDP',''].includes(incoVal) &&
    <fieldset className="flex-grow-1 pt-4"> 
      <select
        name={attributes.attributeCode}
        value={value}
        onChange={(e) => {
          e.preventDefault();
          setValue((response: any) => {
            return {
              ...response,
              [attributes.attributeCode]: e.target.value,
            };
          });
        }}
      >
        {locations?.map((option:any, idx:any) => {
          return (
            <option key={idx} value={option.locationCode}>
              {option.locationName}
            </option>
          );
        })}
      </select>
      
    </fieldset>
    
    }
    </div>
    )
  }
  
  switch (attributes.inputType) {
    case "text":
    case "email":
    case "password":
      return <FormTextField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "textArea":
      return <FormTextField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "radioGroup":
      return <FormRadioField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "checkboxGroup":
      return <FormCheckboxGroupField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "checkbox":
      return <FormCheckboxField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "yesNo":
      return <FormYesNoField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "select":
      return <FormSelectField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "typeSelect":
      return <FormSelectField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "multiselect":
      return <FormMultiSelectField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "date":
      return <FormChronoField key={attributes.attributeCode} {...attributes} {...rest} chronoType="date" />;
    case "time":
      return <FormChronoField key={attributes.attributeCode} {...attributes} {...rest} chronoType="time" />;
    case "dateTime":
      return <FormChronoField key={attributes.attributeCode} {...attributes} {...rest} chronoType="datetime-local" />;
    case "file":
      return <FormFileField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "relatedObjectSelect":
      return <FormSelectField key={attributes.attributeCode} {...attributes} {...rest} />;
    default:
      return <h2>Not found - {attributes.inputType}</h2>;
  }
};
