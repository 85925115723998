import { useEffect, useMemo, useState, useRef } from "react";
import { useElementContext } from "@ultracommerce/react-storefront/global/src/contexts/ElementContext";
import { Product, ProductModifier, Sku } from "@ultracommerce/react-storefront/global/src/interface/Product";
import { useSelector } from "react-redux";
import { getPrimaryAddress } from "@ultracommerce/react-storefront/global";
import { toast } from "react-toastify";
import { Button } from "@ultracommerce/react-storefront/global/src/components";
import { bulkCalculatePrice } from "../../../actions/cartActions";
import { useCustomFormatCurrency } from "../../../hooks/useFormatCurrency";
import { ProductModifiers } from "./ProductModifiers";
import { calStepAndMinQuantity, calMinDeliveryDate, genModelNumber } from "../../../utils";
import { useStepQuantityInput } from "../../../hooks/useStepQuantityInput";
import { useTranslation } from "react-i18next";

export interface ProductModifiersModalProps {
  defaultSkuId?: string;
  product: Product & { modifiers: ProductModifier[] };
  setShow: (open: boolean) => void;
  addItem?: (sku:Sku, skuConfiguration: { [key: string]: string }, calculatedData: { [key: string]: string }, requestedDeliveryDate: string, materialNumber: string, itemCount: string) => void;
  startingSkuConfiguration?: { [key: string]: string }
}

const ProductModifiersModal = ({ defaultSkuId, product, setShow, addItem, startingSkuConfiguration }: ProductModifiersModalProps) => {
  const { t } = useTranslation();
  const [sku, setSku] = useState(product.skus.find(({ skuID }) => skuID === (defaultSkuId || product.defaultSku_skuID)) || product.skus[0]);
  const [skuConfiguration, setSkuConfiguration] = useState<{ [key: string]: string }>(startingSkuConfiguration && Object.keys(startingSkuConfiguration).length > 0 ? startingSkuConfiguration : {});
  const { addressZoneName } = useSelector((state : any) => state.userReducer);
  //@ts-ignore
  if(product) product.modifiers = product.modifiers || (addressZoneName === "US" ? product?.modifiersUS : product?.modifiersEU);
  const showAddItem = useMemo(
    () => product.modifiers?.every((modifier: any) => !modifier.required || skuConfiguration[modifier.key]),
    [product.modifiers, skuConfiguration],
  );
  const primaryAddress = useSelector(getPrimaryAddress);
  const [calculatedData, setCalculatedData] = useState<any>();
  const {
    CommonModule: { Modal, Portal },
  } = useElementContext();
  const [formatCurrency] = useCustomFormatCurrency({});
  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState('');
  const [materialNumber, setMaterialNumber] = useState('');
  const { stepQty, minQty } = calStepAndMinQuantity(product.modifiers, skuConfiguration);
  const [itemCount, setItemCount] = useState(minQty || '1'); 
  useEffect(() => {
    setItemCount(minQty || '1');
  },[minQty])

  const { leadTime, minDeliveryDate } = useMemo(
    () => calMinDeliveryDate(calculatedData?.modelPriceInfo?.skuConfigurationPrice?.effectiveLeadTime),
    [calculatedData?.modelPriceInfo?.skuConfigurationPrice?.effectiveLeadTime]
  );
  useEffect(() => {
      setRequestedDeliveryDate(minDeliveryDate);
  }, [minDeliveryDate]);
  const handleChange = (e: any) => {
    setRequestedDeliveryDate(e.target.value);
  };

  useEffect(()=>{
    setCalculatedData(null);
  },[sku, skuConfiguration, itemCount])

  const inputProps = useStepQuantityInput({ stepQty, minQty })

  const formRef = useRef<HTMLFormElement>(null);
  const [isCalculatingPrice, setCalculatingPrice] = useState(false);
  const [isAddingProductToQuote, setAddingProductToQuote] = useState(false);
  return (
    <Portal>
      <Modal setShow={setShow} title="Select product configuration">
        <form ref={formRef} className="productModifierModal-form" onSubmit={(e) => {
            e.preventDefault();
          }}>
          <h3 className="p-0">{sku?.skuName || sku?.productName || product.productName}</h3>
          <div className="row">
            {product.skus.length > 1 && (
              <div className="col-12">
                <select
                  className="custom-select rounded-pill"
                  required
                  value={sku?.skuID}
                  onChange={(e) => {
                    setSku(
                      product.skus.find(({ skuID }) => skuID === e.target.value) ||
                        product.skus[0]
                    );
                  }}
                >
                  {product.skus?.map((skuOption) => {
                    return (
                      <option key={skuOption.skuID} value={skuOption.skuID}>
                        {skuOption.calculatedSkuDefinition || sku?.skuName}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <ProductModifiers
              modifiers={(product as any).modifiers || []}
              setSkuConfiguration={setSkuConfiguration}
              skuConfiguration={skuConfiguration}
            />
            <div className="d-flex align-items-end gap-4 mb-3">
              <div className="d-flex flex-column">

                <label>Quantity</label>
                <input
                  {...inputProps}
                  type="number"
                  onChange={(event) => {
                    setItemCount(event.target.value);
                  }}
                  value={itemCount}
                  className="form-control rounded-pill"
                  style={{ width: "5rem" }}
                />
              </div>
            </div>
          </div>
          {calculatedData &&
            <>
              <h4>Total incl (Surcharges and Taxes): {formatCurrency(calculatedData?.modelPriceInfo?.totalModelPriceAfterDiscountAfterSurchargeAfterTax, calculatedData?.modelPriceInfo?.currencyCode)}</h4>
              <h5 className="mt-2 fw-normal">{t("frontend.quote.leadTime", { leadTime })}</h5> <p>Note: If you have urgent demand, contact our CSR for air freight option.</p>
              <div className="col-sm-6 my-2 mb-4">
                <div className="form-group">
                  <label htmlFor="requestedDeliveryDate">Preferred Delivery Date:</label>
                  <input
                    className="form-control"
                    value={requestedDeliveryDate}
                    type="date"
                    onChange={handleChange}
                    id="requestedDeliveryDate"
                    min={minDeliveryDate}
                  />
                </div>
              </div>
              <div className="col-sm-12 my-2 mb-4">
                <label htmlFor="materialNumber">Please input the material number of your company, if any:</label>
                <input
                  className="form-control"
                  value={materialNumber}
                  type="text"
                  onChange={(e) => setMaterialNumber(e.target.value)}
                  id="materialNumber"
                />
              </div>
            </>
          }
          <div className="accordion accordion-cart" id="accordioncartExample">
            <div className="accordion-item quote-input-box d-flex">
        
              {showAddItem && !calculatedData && <Button
                classList="btn btn-primary flex-grow-1 text-center d-block"
                disabled={isCalculatingPrice}
                onClick={(e) => {
                  e.preventDefault();
                  setCalculatingPrice(true);
                  if(!primaryAddress?.address?.countrycode){
                    toast.error("Please add an address to your profile to calculate price.");
                    return;
                  }
                  if(!formRef.current || formRef.current.reportValidity()) {
                    //@ts-ignore
                    bulkCalculatePrice([{
                      skuID: sku.skuID,
                      quantity: itemCount,
                      modelNumber: genModelNumber(sku.skuCode, product.modifiers, skuConfiguration)
                    }]).then((response) => {
                      if (response.status === 200) {
                        setCalculatedData(response.data.prices[0])
                        setCalculatingPrice(false);
                      }
                    })
                  }
                }}
              >
                {isCalculatingPrice ? <span
                className="spinner-border spinner-border-sm p-2 text-primary"
                role="status"
                aria-hidden="true"
              ></span> : 'Calculate Price'}
              </Button>}
              {calculatedData && <Button
                classList="btn btn-primary flex-grow-1 text-center d-block"
                disabled={isAddingProductToQuote}
                onClick={(e) => {
                  e.preventDefault();
                  setAddingProductToQuote(true);
                  if(!formRef.current || formRef.current.reportValidity()) {
                    addItem?.(sku, skuConfiguration, calculatedData, materialNumber, requestedDeliveryDate, itemCount);
                    setAddingProductToQuote(true);
                  }
                }}
              >
                Add to Quote
              </Button>}
            </div>
          </div>
        </form>
      </Modal>
    </Portal>
  );
};

export { ProductModifiersModal };
