import { useUtilities } from "@ultracommerce/react-storefront/global";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/swiper-bundle.min.js';
import "swiper/swiper-bundle.min.css";
import { useLocation } from "react-router-dom";

const HTMLcontent = (props) => {
  const {
    customStyleClasses = "",
    contentBody = "",
    systemCode,
    bootStrapLayoutClass,
    images
  } = props;
  let { eventHandlerForWSIWYG } = useUtilities();
  const { pathname } = useLocation();
  SwiperCore.use([Navigation]);

  return (
    <>
      <div
        className={[
          "bodyContent htmlContent",
          customStyleClasses,
          systemCode,
          bootStrapLayoutClass,
        ].join(" ")}
        onClick={eventHandlerForWSIWYG}
        dangerouslySetInnerHTML={{ __html: contentBody }}
      />
      {pathname === "/company-milestones" && (
        <div>
          <section
            className="section section-about section-swiper-time position-relative container"
            id="section-bran4"
          >
            <h3 className="fw-bold fs-2">Our History</h3>
            <div className="mb-lg-5">
              <div className="">
                <div className="mt-auto w-100">
                  <div className="bg-secondaey-es mb-xl-4 mb-xxl-5 swiper-outer">
                    <Swiper
                      modules={[Navigation, Pagination, Scrollbar, A11y]}
                      breakpoints={{
                        992: {
                          slidesPerView: 4,
                        },
                        1680: {
                          slidesPerView: 6,
                        },
                      }}
                      spaceBetween={50}
                      slidesPerView={1.3}
                      navigation
                    >
                      <SwiperSlide className="swiper-slide h-lg-1140">
                        <div className="w-100 d-block homepage-swiper-bg homepage-swiper-circular-up">
                          <div className="py-3 border-bottom border-bottom-lg-none d-lg-flex flex-column-reverse">
                            <h2 className="py-4 homepage-swiper-triangle-up font-size-lg-32 mb-0">
                              2022
                            </h2>
                            <h4 className="mt-3 mb-0 milestone-text">
                              Acquisition by Impro Fluidtek
                            </h4>
                            <img
                              className="py-4 py-lg-2 history-img"
                              src={images?.logo}
                              alt=""
                            />
                            <p className="mb-3">
                              Impro Fluidtek acquired the orbital motor business
                              of Danfoss Power Solutions (Jiangsu) Co., Ltd.
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide h-lg-1140">
                        <div className="w-100 d-block homepage-swiper-bg homepage-swiper-circular-down">
                          <div className="py-3 border-bottom border-bottom-lg-none d-lg-flex flex-column">
                            <h2 className="py-4 homepage-swiper-triangle-up font-size-lg-32 mb-0">
                              2021
                            </h2>
                            <h4 className="mt-3 mb-0 milestone-text">
                              Establishment of Hydraulic R&D Center
                            </h4>
                            <p className="mb-3">
                              Our hydraulic R&D center was established in Wuxi,
                              China
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide h-lg-1140">
                        <div className="w-100 d-block homepage-swiper-bg homepage-swiper-circular-up">
                          <div className="py-3 border-bottom border-bottom-lg-none d-lg-flex flex-column-reverse">
                            <h2 className="py-4 homepage-swiper-triangle-up font-size-lg-32 mb-0">
                              2020
                            </h2>
                            <h4 className="mt-3 mb-0 milestone-text">
                              Establishment of Impro Fluidtek Limited
                            </h4>
                            <p className="mb-3">
                              Impro Fluidtek Limited was established in Wuxi,
                              China
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide h-lg-1140">
                        <div className="w-100 d-block homepage-swiper-bg homepage-swiper-circular-down">
                          <div className="py-3 border-bottom border-bottom-lg-none d-lg-flex flex-column">
                            <h2 className="py-4 homepage-swiper-triangle-up font-size-lg-32 mb-0">
                              2019
                            </h2>
                            <h4 className="mt-3 mb-0 milestone-text">
                              Leading market share in the Scissor Lift market
                            </h4>
                            <p className="mb-3">
                              Our AWP motors (RC/RE series) made a big
                              breakthrough of leading market share in the
                              Scissor Lift industry
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide h-lg-1140">
                        <div className="w-100 d-block homepage-swiper-bg homepage-swiper-circular-up">
                          <div className="py-3 border-bottom border-bottom-lg-none d-lg-flex flex-column-reverse">
                            <h2 className="py-4 homepage-swiper-triangle-up font-size-lg-32 mb-0">
                              2018
                            </h2>
                            <h4 className="mt-3 mb-0 milestone-text">
                              Establishment of Global Innovation Hub
                            </h4>
                            <img
                              className="py-4 py-lg-2 history-img"
                              src={images?.Image_2018}
                              alt=""
                            />
                            <p className="mb-3">
                              Our Zhenjiang plant was selected as the first
                              Global Innovation Hub outside of Denmark within
                              Danfoss Power Solutions Work Function Division
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide h-lg-1140">
                        <div className="w-100 d-block homepage-swiper-bg homepage-swiper-circular-down">
                          <div className="py-3 border-bottom border-bottom-lg-none d-lg-flex flex-column">
                            <h2 className="py-4 homepage-swiper-triangle-up font-size-lg-32 mb-0">
                              2016
                            </h2>
                            <h4 className="mt-3 mb-0 milestone-text">
                              Acquisition by Danfoss
                            </h4>
                            <p className="mb-3">
                              White Drive Products Co., Ltd. was acquired by
                              Danfoss Power Solutions
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide h-lg-1140">
                        <div className="w-100 d-block homepage-swiper-bg homepage-swiper-circular-up">
                          <div className="py-3 border-bottom border-bottom-lg-none d-lg-flex flex-column-reverse">
                            <h2 className="py-4 homepage-swiper-triangle-up font-size-lg-32 mb-0">
                              2012
                            </h2>
                            <h4 className="mt-3 mb-0 milestone-text">
                              Expansion of Product Portfolio
                            </h4>
                            <img
                              className="py-4 py-lg-2 history-img"
                              src={images?.Image_2012}
                              alt=""
                            />
                            <p className="mb-3">
                              Existing product portfolio of hydraulic motors and
                              brakes was expanded to include solutions for the
                              aerial work platform market
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide h-lg-1140">
                        <div className="w-100 d-block homepage-swiper-bg homepage-swiper-circular-down">
                          <div className="py-3 border-bottom border-bottom-lg-none d-lg-flex flex-column">
                            <h2 className="py-4 homepage-swiper-triangle-up font-size-lg-32 mb-0">
                              2009
                            </h2>
                            <h4 className="mt-3 mb-0 milestone-text">
                              Global Engineering and R&D Center
                            </h4>
                            <img
                              className="py-4 py-lg-2 history-img"
                              src={images?.Image_2009}
                              alt=""
                            />
                            <p className="mb-3">
                              Global engineering and R&D center was established
                              in Zhenjiang
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide h-lg-1140">
                        <div className="w-100 d-block homepage-swiper-bg homepage-swiper-circular-up">
                          <div className="py-3 border-bottom border-bottom-lg-none d-lg-flex flex-column-reverse">
                            <h2 className="py-4 homepage-swiper-triangle-up font-size-lg-32 mb-0">
                              2005
                            </h2>
                            <h4 className="mt-3 mb-0 milestone-text">
                              Establishment of Zhenjiang Orbital Motor Plant
                            </h4>
                            <p className="mb-3">
                              The Zhenjiang, China orbital motor plant was
                              established to deliver high-quality, low-speed
                              high-torque hydraulic motor solutions for general
                              market application
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide h-lg-1140">
                        <div className="w-100 d-block homepage-swiper-bg homepage-swiper-circular-down">
                          <div className="py-3 border-bottom border-bottom-lg-none d-lg-flex flex-column">
                            <h2 className="py-4 homepage-swiper-triangle-up font-size-lg-32 mb-0">
                              1976
                            </h2>
                            <h4 className="mt-3 mb-0 milestone-text">
                              Origin of Orbital Motor Business
                            </h4>
                            <p className="mb-3">
                              White Drive Products, the origin of our orbital
                              motor business, was founded by Harvey and Hollis
                              White, Jr. in the US
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export { HTMLcontent };
