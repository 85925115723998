import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PdpPageModel from "@ultracommerce/react-storefront/global/src/pages/ProductDetailsPage/ProductDetailsPage.pageModel";
import { PublicInterface } from "@ultracommerce/react-storefront/global/src/interface/common";
import { useUtilities, isAuthenticated, getPrimaryAddress } from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useSelector } from "react-redux";
import { ProductDetailHeading } from "../../modules/Product/Components/ProductDetailHeading/ProductDetailHeading.component";
import { CalculatedDataProvider } from "../../contexts/CalculatedDataProvider";
import { ProductForm } from "../../modules/Product/Components/ProductForm/ProductForm.component";
import { ProductModifiers, ProductModifiersProps } from "../../modules/Product/Controls/ProductModifiers";

const ProductDetailDisplayView = ({
  productName,
  productDescription,
  helmet,
  isFetching,
  showLoginForPrice,
  additionalInformation,
  productDetailGallery,
  crumbs,
  dynamicContentElements,
  productPrice,
  productDetailHeading,
  productBundle,
  favoritesButton,
  skuOptions,
  skuSelector,
  productModifiers,
  productForm,
  productAttributes
}: PublicInterface<PdpPageModel> & { productModifiers?: ProductModifiersProps }) => {
  const {
    ProductModule,
    CommonModule: { DynamicComponent, Spinner, BreadCrumb },
  } = useElementContext();
  const { t } = useTranslation();
  const primaryAddress = useSelector(getPrimaryAddress);
  if (productAttributes?.product?.attributeSets?.length) {
    productAttributes.attributeSets = productAttributes.product.attributeSets.filter(
      ({ attributeSetCode }: any) => attributeSetCode !== 'json_short_description' && attributeSetCode !== 'ExtendedProductInformation'
    )
  }
  const { eventHandlerForWSIWYG } = useUtilities();
  if (isFetching) {
    return (
      <div className="container mt-5">
        <Spinner />
      </div>
    );
  }
  return (
    <CalculatedDataProvider>
      <div className="bg-light px-4 py-3 pt-4">
        <BreadCrumb crumbs={crumbs} />
      </div>
      {helmet && <Helmet {...helmet} />}
      <div className="container mt-5">
        {favoritesButton && ( //changes-favourites display control from config
          <div className="d-flex justify-content-end">
            <ProductModule.CoreComponents.HeartButton className="btn-wishlist mr-0 flex-end" {...favoritesButton} />
          </div>
        )}
        <div className="row">
          <div className="col-sm-12 col-lg-5 mb-4 mb-md-0">
            {productDetailGallery && <ProductModule.CoreComponents.ProductDetailGallery {...productDetailGallery} />}
            {/*changes-product description moved below the image slider */}
            {productDescription && (
              <div
                className="py-5"
                onClick={eventHandlerForWSIWYG}
                dangerouslySetInnerHTML={{
                  __html: productDescription,
                }}
              />
            )}

            {additionalInformation && (
              <ProductModule.CoreControl.ProductAdditionalInformation {...additionalInformation} />
            )}
          </div>
          <div className="col-sm-12 col-lg-6 offset-lg-1">
            <h3 className="fw-bold mb-3">{productName}</h3>
            {productDetailHeading && <ProductDetailHeading {...productDetailHeading} />}
            {/*productPrice && (
              <div className="my-4 fw-bold">
                <ProductModule.CoreComponents.ProductPrice {...productPrice} />
              </div>
            )*/}

            {isAuthenticated() && skuOptions && <ProductModule.CoreComponents.SkuOptions {...skuOptions} />}
            {isAuthenticated() && skuSelector && <ProductModule.CoreComponents.SkuSelector {...skuSelector} />}
            {isAuthenticated() && productModifiers && <ProductModifiers {...productModifiers} />}
            {isAuthenticated() && primaryAddress?.address.countrycode === '' && (
              <div className="alert alert-warning mt-2" role="alert">
                Kindly ensure the inclusion of an address prior to adding to the quote.
                <br />
                <Link to="/my-account/addresses/new">To input your address, kindly click on the following link. </Link>
              </div>
            )}

            {!isAuthenticated() && (
              <div className="alert alert-warning mt-2 text-center" role="alert">
                <Link to="/my-account/login">Please log in to access pricing information.</Link>
              </div>
            )}

            {isAuthenticated() && productForm && (
              <div className="d-flex align-items-center">
                <ProductForm {...productForm} />
              </div>
            )}

            {showLoginForPrice && ( //changes - config validation and alerts for showing trade price.
              <div className="alert alert-danger pdp-login-alert" role="alert">
                {t("frontend.loginforprice")}
                <br />
                <Link to="/my-account/login"> {t("frontend.account.login")} </Link>
              </div>
            )}

            {dynamicContentElements?.map((rowEl: any, rowIdx: number) => <DynamicComponent el={rowEl} key={rowIdx} />)}
          </div>
          {productAttributes && (
            <div className="row mb-4">
              <div className="col-md-12">
                <ProductModule.CoreComponents.ProductAttributes {...productAttributes} />
              </div>
            </div>
          )}
        </div>
        {productBundle && <ProductModule.CoreComponents.ProductBundle {...productBundle} />}
      </div>
    </CalculatedDataProvider>
  );
};

export default ProductDetailDisplayView;