import { FacetFilterProps } from "@ultracommerce/react-storefront/global/src/modules/ProductListing/Components/FacetFilter/FacetFilter.componentModel";
import { useComponentData } from "@ultracommerce/react-storefront/global/src/modules/ProductListing/Components/FacetFilter/FacetFilter.componentService";
import { FacetFilterView } from "./FacetFilter.componentView";
import { memo } from "react";

const FacetFilter = memo((props: FacetFilterProps) => {
  const { filter } = props;
  const { componentData, componentService } = useComponentData(props);

  if (!filter.options.length) return null;

  return <FacetFilterView componentData={componentData} {...componentService} />;
});

export { FacetFilter };
