import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
// @ts-ignore
import { Tooltip } from "bootstrap/dist/js/bootstrap.bundle.min";

export const useStepQuantityInput = ({ stepQty = "1", minQty = "1" }) => {
  const ref = useRef<HTMLInputElement>(null);
  const { addressZoneName } = useSelector((state: any) => state.userReducer);

  // Fallback for old order item
  if (addressZoneName === "US" && parseInt(stepQty, 10) > 1) {
    minQty = stepQty;
    stepQty = "1";
  }

  const title = useMemo(() => {
    const hints = [];
    if (parseInt(stepQty, 10) > 1) {
      hints.push(`Step quantity ${stepQty}`);
    }
    if (
      parseInt(minQty, 10) > 1 &&
      parseInt(minQty, 10) !== parseInt(stepQty, 10)
    ) {
      hints.push(`Min quantity ${minQty}`);
    }
    if (!hints.length) return "";
    return hints.join(".");
  }, [minQty, stepQty]);

  useEffect(() => {
    if (!!title) {
      const tooltip = new Tooltip(ref.current, {
        container: "body",
      });

      return () => {
        tooltip.dispose();
      };
    }
  }, [title]);

  return {
    ref,
    min: parseInt(stepQty, 10) > parseInt(minQty, 10) ? stepQty : minQty,
    step: stepQty,
    title,
  };
};
