import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useFormatCurrency } from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { Dispatch, SetStateAction } from "react";
import { OrderItem } from "@ultracommerce/react-storefront/global/src/interface/Cart";

export interface MiniCartItemProps {
  cartItem: {
    id: string;
    title: string;
    items: OrderItem[];
    totalItems: number;
    subTotal: number;
    pathLabel: string;
    pathname: string;
    isFetching: boolean;
    cartAction: {
      removeItem?: (productItem: any) => void;
      setActiveCart?: () => void;
      getPrice?: (productItem: any) => any;
    };
    isActive: boolean;
    accordionItemClass?: string;
  };
  isSingleCart: boolean;
  setMiniCartOpen: Dispatch<SetStateAction<boolean>>;
}

export const MiniCartItem = ({ cartItem, isSingleCart, setMiniCartOpen }: MiniCartItemProps) => {
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const [formatCurrency] = useFormatCurrency({});
  const {
    CommonModule: { MiniCartAccordionItem, MiniCartProductItem },
  } = useElementContext();

  const {
    id,
    accordionItemClass,
    title,
    items,
    totalItems,
    subTotal,
    // pathLabel,
    // pathname,
    cartAction,
    isFetching,
    isActive,
  } = cartItem;
  const productItems = items.filter((item) => item.parentOrderItemID === "");
  return (
    <MiniCartAccordionItem
      title={title}
      className={accordionItemClass}
      id={id}
      isActive={isActive}
      isSingleCart={isSingleCart}
    >
      <div className="d-flex justify-content-between py-2">
        <span className="fw-bold ">
          {totalItems} {t("frontend.home.items")}
        </span>
        {/*
        <span
          onClick={(e) => {
            e.preventDefault();
            navigate({ pathname });
            setMiniCartOpen((prevState) => !prevState);
          }
          className="fw-bold link"
        >
          {pathLabel}
        </span> */}
      </div>
      {items.length > 0 && (
        <>
          <div
            style={{
              maxHeight: "50vh",
              overflowY: "auto",
              paddingRight: "10px",
            }}
          >
            {productItems.map((productItem) => {
              return (
                <MiniCartProductItem
                  key={productItem.orderItemID}
                  productItems={productItems}
                  productItem={productItem}
                  cartAction={cartAction}
                  setMiniCartOpen={setMiniCartOpen}
                />
              );
            })}
          </div>
          <div className="d-flex justify-content-center border-top border-bottom py-2 mb-4">
            <label className="text-muted">{t("frontend.home.subtotal")}: </label>{" "}
            <span className="fw-bold">&nbsp;{formatCurrency(subTotal)}</span>
          </div>
          {productItems.filter((item) => item.quoteOnly).length > 0 && (
            <div className="text-danger m-2">
              Your cart has some quote only items, Please review them before checkout.
            </div>
          )}
          {isActive && (
            <Link to="/checkout" className="btn btn-dark d-block ">
              {t("frontend.header.checkout")}
            </Link>
          )}
          {isFetching && <div className="alert alert-info m-2 small">{t("frontend.cart.removeCartItem")}</div>}
        </>
      )}
      {!totalItems && <div className="alert alert-secondary m-2 small">{t("frontend.cart.empty_cart")}</div>}
    </MiniCartAccordionItem>
  );
};
