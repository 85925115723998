import { FormEvent, PropsWithChildren, useCallback, useState } from "react";
import { DynamicFormProps, Payload } from "../DynamicForm/interface";
// import { useElementContext } from "@ultracommerce/react-storefront/global";
import useGeneralFormService from '../DynamicForm/services/generalForm.service'

export interface SubscribeFormProps {
  actionButtonText: string;
  bootStrapLayoutClass: string;
  innerElements: [DynamicFormProps];
  successMessage: string;
  title: string;
}

const SubscribeForm = ({
  title,
  innerElements,
  actionButtonText,
  successMessage,
  bootStrapLayoutClass
}: PropsWithChildren<SubscribeFormProps>) => {
  
  const [defaultPayload, setDefaultPayload] = useState<any>(false);
  const { formResponseSubmitAction } = useGeneralFormService();
  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const ucFormProps = innerElements[0];
      const { value } = e.currentTarget["email"];
      const emailPayload = ucFormProps.formQuestions.reduce<Payload>((acc, cur) => {
        if (cur.inputType === "email") {
          acc[cur.attributeCode] = value;
        }
        return acc;
      }, {});
      await formResponseSubmitAction(emailPayload, ucFormProps?.formReference)
      setDefaultPayload(true)
    },
    [innerElements, formResponseSubmitAction],
  );
  
  return (
    <div className={`container ${bootStrapLayoutClass}`}>
      {defaultPayload ? (
        <div className="text-center" dangerouslySetInnerHTML={{ __html: successMessage }} />
      ) : (
        <>

          <div className="row justify-content-end py-4">
          <form className="d-flex w-100 justify-content-end cetSubscribeForm cetSubscribeForm__form pt-3 px-0" onSubmit={onSubmit}>
            <input
              name="email"
              type="email"
              className="form-control" 
              required
              placeholder="Subscribe to our newsletter"
              aria-label="email"
            />
            <div className="input-group-append">
              <button className="btn btn-sm btn-primary" type="submit">
                {actionButtonText}
              </button>
            </div>
          </form>
          </div>
        </>
      )}
    </div>
  );
};

export default SubscribeForm;
