export const useOrderItemPrice = (orderItem) => {
  return {
    unitPrice:
      orderItem.currencyCode === "EUR"
        ? orderItem.extendedUnitPriceAfterDiscount
        : orderItem.itemTotalSummary.unitPrice,

    showSurcharge: orderItem.currencyCode === "EUR",
    surcharge: orderItem.calculatedSurchargeAmount,
    totalPrice: orderItem.itemTotalSummary.lineTotal,
  };
};
