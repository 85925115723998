import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useState } from "react";
import {
  transformShipping,
  transformPickup,
  useFormatDate,
} from "@ultracommerce/react-storefront/global";
import { getProductRoute } from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useCustomFormatCurrency } from "../../../hooks/useFormatCurrency";

const OrderItem = ({
  quantity,
  sku_skuID,
  sku_product_productName,
  sku_product_urlTitle,
  images,
  BrandName,
  isSeries,
  ProductSeries,
  calculatedExtendedPriceAfterDiscount,
  extendedPriceAfterDiscount,
  extendedUnitPriceAfterDiscount,
  sku_calculatedSkuDefinition,
  sku_imageFile,
  price,
  sku_product_productID,
  orderItemID,
  files,
  orderChildItems,
  quoteDetail,
  orderItems,
  orderStatusType,
  showActions = true,
  quote,
  currencyCode,
  calculatedSurchargeAmount,
  orderTotalSummary,
  unitPrice,
  itemTotalSummary,
  orderItemStatusType_typeName,
  skuConfigurations,
  ...rest
}) => {
  const {
    CartModule,
    CommonModule: { SimpleImage, Modal, AddReview },
  } = useElementContext();
  const [formatCurrency] = useCustomFormatCurrency({});
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const childBundleItems = orderChildItems?.filter(
    (item) => item?.parentOrderItem_orderitemID === orderItemID
  );
  const [formateDate] = useFormatDate();
  const productRouting = useSelector(getProductRoute);
  const [showModal, setModal] = useState(false);
  const [selectedRate, setSelectedRate] = useState(0);
  const [hoveredRate, setHoveredRate] = useState(0);
  return (
    <>
      <div className="row py-lg-3 py-sm-0 d-lg-flex cart-item-box">
        <div className="d-flex d-sm-none d-lg-flex col-gap-10 flex-lg-nowrap flex-sm-wrap">
          <div className="col-4 px-4">
            {isSeries && (
              <span className="product-meta d-block font-size-xs pb-1">
                {ProductSeries}
              </span>
            )}
            {/* <!--- only show this span if part of a bundled product? ---> */}
            <h4 className="product-title mb-2 pt-0">
              <Link
                className="link text-decoration-none text-dark"
                to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`}
              >
                {sku_product_productName}
              </Link>
            </h4>
            <div className="font-size-sm item-sku model-number">
              {rest?.modelNumber && (
                <span className="mr-2">
                  {t("frontend.quote.modelNumber")}: {rest?.modelNumber}
                </span>
              )}
            </div>
            <div className="font-size-sm item-sku">
              {rest?.effectiveLeadTime && (
                <span className="mr-2">
                  {t("frontend.quote.leadTime", {
                    leadTime: parseInt(rest?.effectiveLeadTime, 10),
                  })}
                </span>
              )}
            </div>
            <div className="font-size-sm item-sku">
              {rest?.customerMaterialNumber && (
                <span className="mr-2">
                  {t("frontend.quote.customMaterialNumber")}{": "}
                  {rest?.customerMaterialNumber}
                </span>
              )}
            </div>
            <div className="font-size-sm item-sku">
              {rest?.improCustomCode && (
                <span className="mr-2">
                  {t("frontend.quote.customCode")}{": "}
                  {rest?.improCustomCode}
                </span>
              )}
            </div>
            <div className="font-size-sm item-sku">
              {[
                "qstDraft",
                "qstPendingReview",
                "qstRejected",
                "qstOrderEntryRequestUpdated",
              ].includes(orderStatusType?.typeCode)
                ? rest?.requestedDeliveryDate && (
                    <span className="mr-2">
                      {t("frontend.quote.customerRequestDate")}{": "}
                      {formateDate(rest?.requestedDeliveryDate)}
                    </span>
                  )
                : rest?.plannedDeliveryDate && (
                    <span className="mr-2">
                      {t("frontend.quote.plannedDeliveryDate")}{": "}
                      {formateDate(rest?.plannedDeliveryDate)}
                    </span>
                  )}
            </div>
            <CartModule.CoreControl.OrderItemSkuConfigurations skuConfigurations={skuConfigurations} />
          </div>
          <div className="col-sm-12 col-lg-8">
            <div className="row d-flex justify-content-between">
              <div className="col-sm-3 col-lg-2">
                <span className="d-block">{`${
                  itemTotalSummary?.unitPrice
                    ? formatCurrency(itemTotalSummary?.unitPrice, currencyCode)
                    : formatCurrency(unitPrice, currencyCode)
                }`}</span>

                <span className="d-block">
                  {/* <span className="text-muted mr-2">{`($${listPrice} list)`}</span> */}
                </span>
                {/* <!--- each / list price ---> */}
              </div>
              {currencyCode === "EUR" && (
                <div className="col-sm-3 col-lg-1 px-0">
                  {`${
                    price?.surcharge
                      ? formatCurrency(price?.surcharge, currencyCode)
                      : formatCurrency(calculatedSurchargeAmount, currencyCode)
                  }`}
                </div>
              )}
              <div className="col-sm-3 col-lg-1 px-0">{quantity}</div>
              <div className="col-sm-3 col-lg-2 px-0">
                <div className="row">
                  <div className="col-12 px-0">
                    {`${
                      itemTotalSummary.lineTotal
                        ? formatCurrency(
                            itemTotalSummary.lineTotal,
                            currencyCode
                          )
                        : formatCurrency(
                            extendedPriceAfterDiscount
                              ? extendedPriceAfterDiscount
                              : calculatedExtendedPriceAfterDiscount,
                            currencyCode
                          )
                    }`}
                    {/* <!--- total ---> */}
                  </div>
                </div>
              </div>
              {orderItemStatusType_typeName && (
                <div className="col-lg-4 col-sm-3 px-0">
                  <div className="text-muted">
                    <span className="badge bg-info m-0 p-2 ml-2">
                      {orderItemStatusType_typeName}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Modal
            show={showModal}
            setShow={setModal}
            title={t("frontend.product.review.heading")}
            modalClass="addReviewModal"
            size="large"
          >
            <div className="container">
              <AddReview
                setModal={setModal}
                showModal={showModal}
                selectedRate={selectedRate}
                setSelectedRate={setSelectedRate}
                hoveredRate={hoveredRate}
                setHoveredRate={setHoveredRate}
                sku_product_productID={sku_product_productID}
                sku_product_productName={sku_product_productName}
                sku_product_urlTitle={sku_product_urlTitle}
                sku_skuID={sku_skuID}
                images={images}
              />
            </div>
          </Modal>
          <div className="row">
            {childBundleItems &&
              childBundleItems.length > 0 &&
              childBundleItems.map((childBundleItem, key) => {
                return key !== 0 ? (
                  <>
                    <i className="bi bi-plus-circle col-1 align-self-center"></i>
                    <Link
                      key={childBundleItem.OrderItemID}
                      className="col-2"
                      to={`/${productRouting}/${childBundleItem.sku_product_urlTitle}`}
                    >
                      <SimpleImage
                        className="img-fluid  m-auto image_container productImage border border-light"
                        src={childBundleItem.images?.at(0)}
                        alt={childBundleItem?.sku_product_productName}
                        type="product"
                      />
                      <span className="text-dark">
                        {" "}
                        {`${formatCurrency(childBundleItem.price)} x ${
                          childBundleItem.quantity
                        }`}
                      </span>
                      <p>{childBundleItem?.sku_product_productName}</p>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      key={childBundleItem.OrderItemID}
                      className="col-2"
                      to={`/${productRouting}/${childBundleItem.sku_product_urlTitle}`}
                    >
                      <SimpleImage
                        className="img-fluid  m-auto image_container productImage border border-light"
                        src={childBundleItem.images?.at(0)}
                        alt={childBundleItem?.sku_product_productName}
                        type="product"
                      />
                      <span className="text-dark">
                        {" "}
                        {`${formatCurrency(childBundleItem.price)} x ${
                          childBundleItem.quantity
                        }`}
                      </span>
                      <p>{childBundleItem?.sku_product_productName}</p>
                    </Link>
                  </>
                );
              })}
          </div>
        </div>
        <div className="d-block d-lg-none">
          <div className="card-header">
            <h4 className="p-0 mb-0">{t("frontend.cart.item")}</h4>
          </div>
          <div className="ps-3 pt-2">
            <h4 className="mb-0 p-0">
              <Link
                className="link"
                to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`}
              >
                {sku_product_productName}
              </Link>
            </h4>

            <div className="font-size-sm item-sku model-number">
              {rest?.modelNumber && (
                <span className="mr-2">
                  {t("frontend.quote.modelNumber")}: {rest?.modelNumber}
                </span>
              )}
            </div>
            <div className="font-size-sm item-sku">
              {rest?.effectiveLeadTime && (
                <span className="mr-2">
                  {t("frontend.quote.leadTime", {
                    leadTime: parseInt(rest?.effectiveLeadTime, 10),
                  })}
                </span>
              )}
            </div>
            <div className="font-size-sm item-sku">
              {rest?.customerMaterialNumber && (
                <span className="mr-2">
                  {t("frontend.quote.customMaterialNumber")}{": "}
                  {rest?.customerMaterialNumber}
                </span>
              )}
            </div>
            <div className="font-size-sm item-sku">
              {rest?.improCustomCode && (
                <span className="mr-2">
                  {t("frontend.quote.customCode")}{": "}
                  {rest?.improCustomCode}
                </span>
              )}
            </div>
            <div className="font-size-sm item-sku">
              {[
                "qstDraft",
                "qstPendingReview",
                "qstRejected",
                "qstOrderEntryRequestUpdated",
              ].includes(orderStatusType?.typeCode)
                ? rest?.requestedDeliveryDate && (
                    <span className="mr-2">
                      {t("frontend.quote.customerRequestDate")}{": "}
                      {formateDate(rest?.requestedDeliveryDate)}
                    </span>
                  )
                : rest?.plannedDeliveryDate && (
                    <span className="mr-2">
                      {t("frontend.quote.plannedDeliveryDate")}{": "}
                      {formateDate(rest?.plannedDeliveryDate)}
                    </span>
                  )}
            </div>
            <CartModule.CoreControl.OrderItemSkuConfigurations skuConfigurations={skuConfigurations}/>
          </div>
          <ul className="list-group list-group-flush ">
            <li className="list-group-item d-flex justify-content-between border-top mt-2">
              <h6 className="my-0">{t("frontend.cart.unitPrice")}</h6>
              <span className="float-end">
                <strong>
                  {`${
                    itemTotalSummary?.unitPrice
                      ? formatCurrency(
                          itemTotalSummary?.unitPrice,
                          currencyCode
                        )
                      : formatCurrency(unitPrice, currencyCode)
                  }`}
                </strong>
              </span>
            </li>
            {currencyCode === "EUR" && (
              <li className="list-group-item d-flex justify-content-between ">
                <h6 className="my-0"> {t("frontend.cart.surcharge")}</h6>
                <span className="float-end">
                  <div className="col-sm-3">
                    {`${
                      price?.surcharge
                        ? formatCurrency(price?.surcharge, currencyCode)
                        : formatCurrency(
                            calculatedSurchargeAmount,
                            currencyCode
                          )
                    }`}
                  </div>
                </span>
              </li>
            )}
            <li className="list-group-item d-flex justify-content-between ">
              <h6 className="my-0"> {t("frontend.cart.quantity")}</h6>
              <span className="float-end">
                <strong>{quantity}</strong>
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between ">
              <h6 className="my-0"> {t("frontend.cart.lineTotal")}</h6>
              <span className="float-end">
                <strong>
                  {`${
                    itemTotalSummary.lineTotal
                      ? formatCurrency(itemTotalSummary.lineTotal, currencyCode)
                      : formatCurrency(
                          extendedPriceAfterDiscount
                            ? extendedPriceAfterDiscount
                            : calculatedExtendedPriceAfterDiscount,
                          currencyCode
                        )
                  }`}
                </strong>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const OrderItemV2 = ({
  quantity,
  sku_skuID,
  sku_product_productName,
  sku_product_urlTitle,
  images,
  BrandName,
  isSeries,
  ProductSeries,
  calculatedExtendedPriceAfterDiscount,
  extendedPriceAfterDiscount,
  extendedUnitPriceAfterDiscount,
  sku_calculatedSkuDefinition,
  sku_imageFile,
  price,
  sku_product_productID,
  orderItemID,
  files,
  isOrder,
  orderChildItems,
  quoteDetail,
  orderItems,
  orderStatusType,
  showActions = true,
  quote,
  currencyCode,
  calculatedSurchargeAmount,
  orderTotalSummary,
  unitPrice,
  itemTotalSummary,
  orderItemStatusType_typeName,
  skuConfigurations,
  publicRemoteID,
  ...rest
}) => {
  const {
    CommonModule: { Modal, AddReview },
    CartModule
  } = useElementContext();
  const [formatCurrency] = useCustomFormatCurrency({});
  const { t } = useTranslation();
  const [formateDate] = useFormatDate();
  const productRouting = useSelector(getProductRoute);
  const [showModal, setModal] = useState(false);
  const [selectedRate, setSelectedRate] = useState(0);
  const [hoveredRate, setHoveredRate] = useState(0);
  return (
    <>
      <tr>
      {isOrder && <th><h4 className="p-1">{publicRemoteID}</h4></th>}
      <th>
            {isSeries && (
              <span className="product-meta d-block font-size-xs pb-1">
                {ProductSeries}
              </span>
            )}
            {/* <!--- only show this span if part of a bundled product? ---> */}
            <h4 className="product-title mb-2 pt-0">
              <Link
                className="link text-decoration-none text-dark"
                to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`}
              >
                {sku_product_productName}
              </Link>
            </h4>
            <div className="font-size-sm item-sku model-number">
              {rest?.modelNumber && (
                <span className="mr-2">
                  {t("frontend.quote.modelNumber")}: {rest?.modelNumber}
                </span>
              )}
            </div>
            <div className="font-size-sm item-sku">
              {rest?.effectiveLeadTime && (
                <span className="mr-2">
                  {t("frontend.quote.leadTime", {
                    leadTime: parseInt(rest?.effectiveLeadTime, 10),
                  })}
                </span>
              )}
            </div>
            <div className="font-size-sm item-sku">
              {rest?.customerMaterialNumber && (
                <span className="mr-2">
                  {t("frontend.quote.customMaterialNumber")}{": "}
                  {rest?.customerMaterialNumber}
                </span>
              )}
            </div>
            <div className="font-size-sm item-sku">
              {rest?.improCustomCode && (
                <span className="mr-2">
                  {t("frontend.quote.customCode")}{": "}
                  {rest?.improCustomCode}
                </span>
              )}
            </div>
            <div className="font-size-sm item-sku">
              {[
                "qstDraft",
                "qstPendingReview",
                "qstRejected",
                "qstOrderEntryRequestUpdated",
              ].includes(orderStatusType?.typeCode)
                ? rest?.requestedDeliveryDate && (
                    <span className="mr-2">
                      {t("frontend.quote.customerRequestDate")}{": "}
                      {formateDate(rest?.requestedDeliveryDate)}
                    </span>
                  )
                : rest?.plannedDeliveryDate && (
                    <span className="mr-2">
                      {t("frontend.quote.plannedDeliveryDate")}{": "}
                      {formateDate(rest?.plannedDeliveryDate)}
                    </span>
                  )}
            </div>
            <CartModule.CoreControl.OrderItemSkuConfigurations skuConfigurations={skuConfigurations}/>
          </th>
          <th>
           <span className="d-block">{`${
                  itemTotalSummary?.unitPrice
                    ? formatCurrency(itemTotalSummary?.unitPrice, currencyCode)
                    : formatCurrency(unitPrice, currencyCode)
                }`}</span>

                <span className="d-block">
                  {/* <span className="text-muted mr-2">{`($${listPrice} list)`}</span> */}
                </span>
                {/* <!--- each / list price ---> */}
          </th>

              {currencyCode === "EUR" && (
                <th>
                  {`${
                    price?.surcharge
                      ? formatCurrency(price?.surcharge, currencyCode)
                      : formatCurrency(calculatedSurchargeAmount, currencyCode)
                  }`}
                </th>
              )}
              <th>{quantity}</th>
              <th>
                    {`${
                      itemTotalSummary.lineTotal
                        ? formatCurrency(
                            itemTotalSummary.lineTotal,
                            currencyCode
                          )
                        : formatCurrency(
                            extendedPriceAfterDiscount
                              ? extendedPriceAfterDiscount
                              : calculatedExtendedPriceAfterDiscount,
                            currencyCode
                          )
                    }`}
                    {/* <!--- total ---> */}
              </th>
              {orderItemStatusType_typeName && (
                <th>
                  <div className="text-muted">
                    <span className="badge bg-info m-0 p-2 ml-2">
                      {orderItemStatusType_typeName}
                    </span>
                  </div>
                </th>
              )}
          <Modal
            show={showModal}
            setShow={setModal}
            title={t("frontend.product.review.heading")}
            modalClass="addReviewModal"
            size="large"
          >
            <div className="container">
              <AddReview
                setModal={setModal}
                showModal={showModal}
                selectedRate={selectedRate}
                setSelectedRate={setSelectedRate}
                hoveredRate={hoveredRate}
                setHoveredRate={setHoveredRate}
                sku_product_productID={sku_product_productID}
                sku_product_productName={sku_product_productName}
                sku_product_urlTitle={sku_product_urlTitle}
                sku_skuID={sku_skuID}
                images={images}
              />
            </div>
          </Modal>
      </tr>
    </>
  );
};

const OrderShipments = ({ shipments, files }) => {
  const { t } = useTranslation();
  return (
    <div className="order-items mr-3">
      {shipments &&
        shipments.map((shipment, index) => {
          return (
            <>
              <div className="card mb-4" key={index}>
                <div className="card-header bg-secondary text-light">
                  <div className="row">
                    <div className="col-sm-6">{`Shipment ${index + 1} of ${
                      shipments.length
                    }`}</div>
                    {shipment.trackingNumber && (
                      <div className="col-sm-6 text-right">
                        {"Tracking Number: "}
                        <a href="/#to-shipper" target="_blank">
                          {shipment.trackingNumber}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <h4 className="p-0">{t("frontend.cart.item")}</h4>
                    </div>
                    <div className="col-sm-12 col-md-6 d-none d-lg-block">
                      <div className="row">
                        <div className="col-sm-3">
                          <small>{t("frontend.cart.unitPrice")}</small>
                        </div>
                        <div className="col-sm-3">
                          <small>{t("frontend.cart.surcharge")}</small>
                        </div>
                        <div className="col-sm-3">
                          <small>{t("frontend.cart.quantity")}</small>
                        </div>
                        <div className="col-sm-3">
                          <small>{t("frontend.cart.total")}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body py-0">
                  {shipment.orderItems &&
                    shipment.orderItems.map((item, index) => {
                      return <OrderItem key={index} {...item} files={files} />;
                    })}
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};

const OrderFulfilments = ({ fulfilments, files, delivered, orderPayments }) => {
  const {
    CommonModule: { ShippingAddressDetails, PickupLocationDetails },
  } = useElementContext();
  const { t } = useTranslation();
  const {
    billingAddress_streetAddress,
    billingAddress_stateCode,
    billingAddress_postalCode,
    billingAddress_name,
    billingAddress_city,
    billingAddress_emailAddress,
  } = orderPayments || {};

  return (
    <>
      {fulfilments &&
        fulfilments.map((fulfilment, index) => {
          return (
            <div className="d-flex py-3 cart-item-box" key={index}>
              <div className="pe-0 col-12">
                <div className="table-responsive font-size-md">
                  <table className="table table-striped table-borderless table-quote-items">
                    <thead className="thead-light">
                      <tr>
                        <th>{t("frontend.order.lineNumber")}</th>
                        <th>{t("frontend.cart.item")}</th>
                        <th>{t("frontend.cart.unitPrice")}</th>
                        {delivered?.currencyCode === "EUR" && <th>{t("frontend.cart.surcharge")}</th>}
                        <th>{t("frontend.cart.quantity")}</th>
                        <th>{t("frontend.cart.lineTotal")}</th>
                        <th>{t("frontend.order.statusText")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fulfilment.orderFulfillmentItems &&
                        fulfilment.orderFulfillmentItems
                          .filter((item) => !item.parentOrderItem_orderitemID)
                          .map((item) => {
                            return (
                              <OrderItemV2
                                isOrder
                                key={item.orderItemID}
                                {...item}
                                files={files}
                                orderChildItems={fulfilment.orderFulfillmentItems.filter(
                                  (item) => item.parentOrderItem_orderitemID
                                )}
                              />
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          );
        })}
      <div className="row">
        <div className="d-flex col-gap-10 flex-lg-nowrap flex-sm-wrap">
          {fulfilments &&
            fulfilments.map((fulfilment, index) => {
              return (
                <div className="card mb-4 col-sm-12 px-0" key={index}>
                  <div className="card-header">
                    <h4 className="col-sm-6 mb-0 p-0">
                      {fulfilment.orderFulfillmentMethodName}
                    </h4>
                    {fulfilment.trackingNumbers.length > 0 && (
                      <h4 className="col-sm-6 text-right mb-0 p-0">{`Tracking: ${fulfilment.trackingNumbers.join(
                        ", "
                      )}`}</h4>
                    )}
                  </div>

                  {fulfilment.fulfillmentMethodType === "shipping" && (
                    <div className="row p-3">
                      <>
                        <h6 className="h6 fw-bold billing-address-title">
                          {t("frontend.checkout.billing_address")}
                        </h6>
                        {orderPayments?.billingAddress_name && (
                          <p>
                            {billingAddress_name} <br />
                            {billingAddress_streetAddress} <br />
                            {`${billingAddress_city}, ${billingAddress_stateCode} ${billingAddress_postalCode}`}{" "}
                            <br />
                            <span className="text-truncate text-dark d-block">
                              {billingAddress_emailAddress}
                            </span>
                          </p>
                        )}
                      </>
                      <ShippingAddressDetails
                        className=""
                        orderFulfillment={{
                          shippingAddress: transformShipping(
                            fulfilment.orderFulfillmentItems.at(0)
                          ),
                        }}
                        displayOnly={true}
                      />
                      {delivered.orderShippingDispatchOptionsCustom && (
                        <span>
                          <b>Shipping Dispatch Option:</b>
                          <br />
                          {delivered.orderShippingDispatchOptionsCustom ===
                          "shipCompleteOrder"
                            ? "Ship Complete Order"
                            : "Partial Shipment Accepted"}
                        </span>
                      )}
                    </div>
                  )}
                  {fulfilment.fulfillmentMethodType === "pickup" && (
                    <div className="card-header ">
                      <div className="row">
                        <PickupLocationDetails
                          className=""
                          pickupLocation={transformPickup(
                            fulfilment.orderFulfillmentItems.at(0)
                          )}
                          displayOnly={true}
                        />{" "}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export { OrderShipments, OrderFulfilments, OrderItem, OrderItemV2 };
