import { OrderItemSkuConfigurationsProps } from "@ultracommerce/react-storefront/global/src/modules/Cart/Controls/OrderItemSkuConfigurations";

export const OrderItemSkuConfigurations = ({
  skuConfigurations,
}: OrderItemSkuConfigurationsProps) => {
  if (!skuConfigurations?.length) return null;
  return (
    <details>
      <summary className="py-2">View configuration</summary>
      <div
        style={{
          display: "grid",
          gridRowGap: "5px",
        }}
      >
        {skuConfigurations.map((config) => {
          return (
            <div key={config.groupCode}>
              <small>
                <b className="text-capitalize">{config.groupName}</b>:{" "}
                {config.name}
              </small>
            </div>
          );
        })}
      </div>
    </details>
  );
};
