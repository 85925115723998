import { useCallback, useEffect } from "react";
import { ProductFormView } from "./ProductForm.componentView";
import { useComponentModel } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductForm/ProductForm.componentService";
import { Quote } from "@ultracommerce/react-storefront/global/src/interface/Quote";
import { DisplayConfig } from "@ultracommerce/react-storefront/global";
import { Sku } from "@ultracommerce/react-storefront/global/src/interface/Product";

interface ProductFormProps {
  sku: Sku;
  showInputLabel: boolean;
  quantityInputMode: DisplayConfig["quantityInputMode"];
  showInventory: DisplayConfig["showInventory"];
  skuConfiguration?: any;
  modelNumber?: string;
  wishlists?: any;
  showExistingQuoteAndLists?: any;
  stepQty?: string;
  minQty?: string;
}

const ProductForm = (props: ProductFormProps) => {
  const { componentData, componentService } = useComponentModel(props);
  const { getAllCartOrQuotes } = componentService;
  useEffect(() => {
    if (componentData.authenticationRequiredForAddToCart) return;
    getAllCartOrQuotes();
  }, [componentData.authenticationRequiredForAddToCart, getAllCartOrQuotes]);
  const skuConfiguration = props.skuConfiguration;
  const addToCartOrQuote = useCallback(
    (quote?: Quote) => {
      if (componentData.isLoading) return;
      componentService.addToQuote(
        componentData.sku,
        componentData.itemCount,
        quote,
        skuConfiguration
      );
    },
    // eslint-disable-next-line
    [
      componentData.isLoading,
      componentData.itemCount,
      componentData.sku,
      componentService,
      skuConfiguration,
    ]
  );

  // @ts-ignore
  componentData.stepQty = props.stepQty;
  // @ts-ignore
  componentData.minQty = props.minQty;
  // @ts-ignore
  componentData.modelNumber = props.modelNumber;

  useEffect(() => {
    componentService.setItemCount(parseInt(props.minQty || "1", 10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.minQty]);

  return (
    <ProductFormView
      viewData={componentData}
      {...componentService}
      addToCartOrQuote={addToCartOrQuote}
    />
  );
};

export { ProductForm, type ProductFormProps };
