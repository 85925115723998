import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  axios,
  getSdkURL,
  useAccountProfile,
} from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const AccountProfile = ({ crumbs, title, contentBody, contentTitle }) => {
  const {
    CommonModule: { AccountContent, AccountLayout },
  } = useElementContext();
  const { t } = useTranslation();
  const { formik } = useAccountProfile();
  const user = useSelector((state) => state.userReducer);
  const [ownerAccountAddresses, setOwnerAccountAddresses] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${getSdkURL()}api/scope/getOwnerAccountAddresses`
        );
        setOwnerAccountAddresses(response?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <AccountLayout crumbs={crumbs} title={title}>
      <AccountContent />
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="accountFirstName">
                {t("frontend.account.first_name")}
              </label>
              <input
                className="form-control"
                type="text"
                id="accountFirstName"
                value={formik.values.accountFirstName}
                onChange={formik.handleChange}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="accountLastName">
                {t("frontend.account.last_name")}
              </label>
              <input
                className="form-control"
                type="text"
                id="accountLastName"
                value={formik.values.accountLastName}
                onChange={formik.handleChange}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-sm-6 mt-1">
            <div className="form-group">
              <label htmlFor="accountEmailAddress">
                {t("frontend.account.email")}
              </label>
              <input
                className="form-control"
                type="email"
                id="accountEmailAddress"
                value={formik.values.accountEmailAddress}
                onChange={formik.handleChange}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="accountPhoneNumber">Phone Number</label>
              <input
                className="form-control"
                type="text"
                id="accountPhoneNumber"
                value={formik.values.accountPhoneNumber}
                onChange={formik.handleChange}
                required=""
                disabled={true}
              />
            </div>
          </div>
          <div className="col-sm-6 mt-1">
            <div className="form-group">
              <label htmlFor="accountCompany">
                {t("frontend.account.company")}
              </label>
              <input
                className="form-control"
                value={formik.values.accountCompany}
                type="text"
                onChange={formik.handleChange}
                id="accountCompany"
                disabled={true}
              />
            </div>
          </div>
        </div>
      </form>
      <hr />
      {ownerAccountAddresses &&
        ownerAccountAddresses?.accountAddresses
          .filter(
            (address) =>
              address.accountAddressID === ownerAccountAddresses.primaryAccountAddressID
          )
          .map((address) => {
            return (
              <div className="row" key={address.accountAddressID}>
                <div className="col-sm-12">
                  <h5>Billing Address Details </h5>
                  <div className="form-group">
                    <label htmlFor="accountAddressName">Company Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="accountAddressName"
                      value={address.accountAddressName}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-sm-6 mt-1">
                  <div className="form-group">
                    <label htmlFor="address_streetAddress">Street</label>
                    <input
                      className="form-control"
                      type="email"
                      id="address_streetAddress"
                      value={address?.address_streetAddress}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="address_city">City</label>
                    <input
                      className="form-control"
                      type="text"
                      id="address_city"
                      value={address?.address_city}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-sm-6 mt-1">
                  <div className="form-group">
                    <label htmlFor="address_stateCode">State</label>
                    <input
                      className="form-control"
                      value={address?.address_stateCode}
                      type="text"
                      id="address_stateCode"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-sm-6 mt-1">
                  <div className="form-group">
                    <label htmlFor="address_postalCode">Postal Code</label>
                    <input
                      className="form-control"
                      value={address?.address_postalCode}
                      type="text"
                      id="address_postalCode"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-sm-6 mt-1">
                  <div className="form-group">
                    <label htmlFor="address_countryCode">Country</label>
                    <input
                      className="form-control"
                      value={address?.address_countryCode}
                      type="text"
                      id="address_countryCode"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            );
          })}{" "}
      <div>
        <h3>Approved for Account Credit:</h3>
        <div>
          <ToggleSwitch
            id="newsletter"
            checked={user?.enableAccountCredit}
            onChange={(e) => {}}
            disabled={true}
          />
          <label htmlFor="newsletter">
            <Link to="/account-credit-application">
              Account Credit Application
            </Link>
          </label>
          {user?.primaryAddress?.address?.countrycode === "US" && (
            <label htmlFor="newsletter">
              <Link to="/bank-authorization">Bank Authorization letter</Link>
            </label>
          )}
        </div>
      </div>
      <div className="col mt-3">
        <div
          className="form-control"
          style={{
            backgroundColor: "#fbc8be",
            color: "#f56c52",
            border: "1px solid #f56c52",
            fontSize: "13px",
          }}
        >
          Profile information cannot be edited from the web portal.
          <br />
          If your information needs updating please contact our CSR.
        </div>
      </div>
    </AccountLayout>
  );
};

const ToggleSwitch = ({
  id,
  name,
  checked,
  onChange,
  optionLabels,
  small,
  disabled,
}) => {
  function handleKeyPress(e) {
    if (e.keyCode !== 32) return;

    e.preventDefault();
    onChange(!checked);
  }

  return (
    <div className={"toggle-switch" + (small ? " small-switch" : "")}>
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={checked || false}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      {id ? (
        <label
          className="toggle-switch-label"
          tabIndex={disabled ? -1 : 1}
          onKeyDown={(e) => handleKeyPress(e)}
          htmlFor={id}
        >
          <span
            className={
              disabled
                ? "toggle-switch-inner toggle-switch-disabled"
                : "toggle-switch-inner"
            }
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]}
            tabIndex={-1}
          />
          <span
            className={
              disabled
                ? "toggle-switch-switch toggle-switch-disabled"
                : "toggle-switch-switch"
            }
            tabIndex={-1}
          />
        </label>
      ) : null}
    </div>
  );
};

// Set optionLabels for rendering.
ToggleSwitch.defaultProps = {
  optionLabels: ["Yes", "No"],
};

export { AccountProfile };
