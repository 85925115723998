import { getCurrencies, getSiteConfig } from "@ultracommerce/react-storefront/global";
import { useSelector } from "react-redux";

const useCustomFormatCurrency = ({ minimumFractionDigits = 2 }) => {
  const currencies = useSelector(getCurrencies);
  const { currencyCode } = useSelector(getSiteConfig);
  const format = (value, customCurrencyCode) => {
    const isNegative = value < 0;
    value = parseFloat(Math.abs(value)).toLocaleString(undefined, {
      minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    });
    const currency = customCurrencyCode ? currencies[customCurrencyCode] : currencies[currencyCode];
    return value
      ? `${isNegative ? "-" : ""}${currency?.currencySymbol}${value}${
          currency?.formatMask
        }`
      : "";
  };
  return [format];
};
export { useCustomFormatCurrency };
