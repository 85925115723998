import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useId, useState } from "react";
import { isExternalURL } from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { getSdkURL } from "@ultracommerce/react-storefront/global";

const ListingBanner = ({
  heading = "",
  crumbs = undefined,
  images = [],
  description = "",
  subHeading = "",
  type = "brand",
  showCrumbs = true,
  isCollapsible = true,
}) => {
  const {
    CommonModule: { BreadCrumb },
  } = useElementContext();
  const { t } = useTranslation();
  const id = useId();
  const [imageMissing, setImageMissing] = useState(false);
  const host = getSdkURL();
  return (
    <div className="container-fluid listingBanner d-flex flex-column justify-content-center p-0">
      <div className="row">
        {!imageMissing && images?.filter((image)=>image?.includes('https')).map((image) => {
          return (
            <div
              className={`col-12 col-lg-4 text-center banner-img collapse multi-collapse d-flex justify-content-center ${
                isCollapsible ? "" : "show"
              }`}
              id="collapseimage"
              key={id}
            >
              {images && (
                <img
                  src={`${isExternalURL(image) ? "" : host}${images?.at(0)}`}
                  alt={heading}
                  style={{
                    maxHeight: "300px",
                    background: "#fff",
                    zIndex: "-1",
                  }}
                  type={type}
                  onError={() => setImageMissing(true)}
                  onLoad={() => setImageMissing(false) }
                />
              )}
            </div>
          );
        })}
        <div
          className={`flex-grow-1 col-xs-12 col-sm-7 col-md-6 col-lg-8 p-4 d-flex flex-column justify-content-center ${
            isCollapsible ? "" : "flex-grow-1"
          }`}
        >
          <div className="row text-left">
            {type === "brand" && (
              <Link
                className={`text-uppercase small banner-collapse-link collapse multi-collapse pb-2 ${
                  isCollapsible ? "" : "show"
                }`}
                to="/brands"
                id="collapselink"
              >
                {t("frontend.product.shopByBrand")}
              </Link>
            )}

            <h1 className="align-content-start pt-0 mb-0">{heading}</h1>
            {!!subHeading && (
              <h4 className="text-secondary m-0">{subHeading}</h4>
            )}
          </div>

          <div
            className={`row text-left banner-desc collapse multi-collapse px-1 ${
              isCollapsible ? "" : "show"
            }`}
            id="collapsedesc"
          >
            <span
              className="mb-0"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>

        {isCollapsible && (
          <div className="d-flex mt-2 mt-md-3 ms-3 ms-sm-0 form-check form-switch col-lg-4 col-md-5 col-sm-5 justify-content-end banner-switch-div">
            <input
              type="checkbox"
              className="form-check-input listing-banner-switch"
              role="switch"
              id="flexSwitchCheckDefault"
              aria-expanded="false"
              data-bs-toggle="collapse"
              data-bs-target=".multi-collapse"
              aria-controls="collapseimage collapselink collapsedesc"
            />
          </div>
        )}
      </div>

      {showCrumbs && crumbs && (
        <div className="row">
          <div className="col-12 text-center">
            <BreadCrumb crumbs={crumbs} type={type} />
          </div>
        </div>
      )}
    </div>
  );
};

export { ListingBanner };
