import { useState, useEffect, useCallback } from "react";
import {
  getOrder,
  useElementContext,
  useGetOrderDetails,
} from "@ultracommerce/react-storefront/global";
import { CalculatedDataProvider } from "../../contexts/CalculatedDataProvider";
import { useDispatch } from "react-redux";
import CustomCodeTab from "../../components/DraftOrder/CustomCodeTab";

const DraftOrder = ({ orderID = "" }) => {
  const {
    CommonModule: {
      DraftOrderHeader,
      DraftOrderTab,
      AddProductsTab,
      QuickAddTab,
      CSVAddTab,
      Spinner,
    },
  } = useElementContext();
  const [showFilters, setShowFilters] = useState(false);
  const [orderFilter, setOrderFilter] = useState(true);
  // eslint-disable-next-line
  const [activeTab, setActiveTab] = useState("draft");
  const [showSummary, setShowSummary] = useState(false);
  const [orderEditable, setOrderEditable] = useState(true);
  const [filter, setFilter] = useState({
    orderID,
    categoryID: "",
    brandID: "",
    keyword: "",
  });
  const [quote, setQuoteDetail] = useState();
  let [order, setRequest] = useGetOrderDetails(filter);

  const [isAddingProduct, setAddingProduct] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getOrder({
            params: {
              orderID,
            },
            returnQuote: true,
            isQuote: true,
          })
        );
        const data = response.success();
        setQuoteDetail(data?.quote);
      } catch (error) {
        // Handle error
      }
    };
    if (!isAddingProduct) fetchData();
  }, [dispatch, orderID, isAddingProduct]);

  const getOrderInfo = useCallback(
    ({ isFetching = true, makeRequest = true, isLoaded = false } = {}) => {
      setRequest((prevState) => ({
        ...prevState,
        isFetching,
        isLoaded,
        params: filter,
        makeRequest,
      }));
    },
    [filter, setRequest]
  );

  useEffect(() => {
    getOrderInfo();
  }, [getOrderInfo]);

  const applyFilter = useCallback((filterItem) => {
    setFilter((prevState) => ({ ...prevState, ...filterItem }));
  }, []);

  const orderInfo = order?.data?.orderInfo?.at(0);
  useEffect(() => {
    if (
      quote?.orderStatusType?.typeCode !== "qstDraft" &&
      quote?.orderStatusType?.typeCode !== undefined
    ) {
      setShowSummary(true);
      setOrderEditable(false);
    }
    // eslint-disable-next-line
  }, [order]);

  const orderFulfillment = order?.data?.orderFulfillments?.at(0);
  const renderDraftOrderTab = () => {
    return (
      <DraftOrderTab
        showFilters={showFilters}
        quote={quote}
        orderFilters={order?.orderFilters}
        orderID={orderID}
        filter={filter}
        setFilter={applyFilter}
        getOrderInfo={getOrderInfo}
        setQuoteDetail={setQuoteDetail}
        showSummary={showSummary}
      />
    );
  };

  return (
    <CalculatedDataProvider>
      <div className="container">
        <DraftOrderHeader
          orderInfo={orderInfo}
          orderFulfillment={
            orderFulfillment === undefined ? null : orderFulfillment
          }
          setShowSummary={setShowSummary}
          showSummary={showSummary}
          orderEditable={orderEditable}
          getOrderInfo={getOrderInfo}
          quote={quote}
          setQuoteDetail={setQuoteDetail}
        />
        {!showSummary && (
          <div className="d-flex col-12 justify-content-between nav-tablist draft-order-tabs">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link text-primary active"
                  id="draft-order-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#draft-order"
                  type="button"
                  role="tab"
                  aria-controls="draft-order"
                  aria-selected="true"
                  onClick={() => {
                    setOrderFilter(true);
                    setActiveTab("draft");
                  }}
                >
                  Summary
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link text-primary"
                  id="addProduct-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#addProduct"
                  type="button"
                  role="tab"
                  aria-controls="addProduct"
                  aria-selected="false"
                  onClick={() => {
                    setOrderFilter(false);
                    setActiveTab("addProduct");
                  }}
                >
                  Add Products +
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link text-primary"
                  id="quickAdd-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#quickAdd"
                  type="button"
                  role="tab"
                  aria-controls="quickAdd"
                  aria-selected="false"
                  onClick={() => {
                    setOrderFilter(false);
                    setActiveTab("quickAdd");
                  }}
                >
                  Model Search
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link text-primary"
                  id="csv-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#csvAdd"
                  type="button"
                  role="tab"
                  aria-controls="csvAdd"
                  aria-selected="false"
                  onClick={() => {
                    setOrderFilter(false);
                    setActiveTab("csvAdd");
                  }}
                >
                  Upload CSV
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link text-primary"
                  id="custom-code-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#customCode"
                  type="button"
                  role="tab"
                  aria-controls="customCode"
                  aria-selected="false"
                  onClick={() => {
                    setOrderFilter(false);
                    setActiveTab("customCode");
                  }}
                >
                  Custom Code
                </button>
              </li>
            </ul>
            {orderFilter && (quote?.orderItems ?? []).length > 0 && (
              <button
                className="btn btn-light btn-rounded my-1"
                onClick={() => setShowFilters(!showFilters)}
              >
                {showFilters ? `Hide Filter` : `Show Filter`}
                <i className="bi bi-toggles2"></i>
              </button>
            )}
          </div>
        )}
        {showSummary && renderDraftOrderTab()}
        {!showSummary && (
          <div className="d-flex">
            <div className="tab-content w-100" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="draft-order"
                role="tabpanel"
                aria-labelledby="draft-order-tab"
              >
                {order.isFetching ? <Spinner /> : renderDraftOrderTab()}
              </div>
              <div
                className="tab-pane fade"
                id="addProduct"
                role="tabpanel"
                aria-labelledby="addProduct-tab"
              >
                <AddProductsTab
                  orderID={orderID}
                  getOrderInfo={getOrderInfo}
                  isAddingProduct={isAddingProduct}
                  setAddingProduct={setAddingProduct}
                />
              </div>
              <div
                className="tab-pane fade"
                id="quickAdd"
                role="tabpanel"
                aria-labelledby="quickAdd-tab"
              >
                <QuickAddTab
                  orderID={orderID}
                  getOrderInfo={getOrderInfo}
                  isAddingProduct={isAddingProduct}
                  setAddingProduct={setAddingProduct}
                />
              </div>
              <div
                className="tab-pane fade"
                id="csvAdd"
                role="tabpanel"
                aria-labelledby="csvAdd-tab"
              >
                <CSVAddTab
                  orderID={orderID}
                  getOrderInfo={getOrderInfo}
                  isAddingProduct={isAddingProduct}
                  setAddingProduct={setAddingProduct}
                />
              </div>
              <div
                className="tab-pane fade"
                id="customCode"
                role="tabpanel"
                aria-labelledby="customCode-tab"
              >
                <CustomCodeTab
                  orderID={orderID}
                  getOrderInfo={getOrderInfo}
                  isAddingProduct={isAddingProduct}
                  setAddingProduct={setAddingProduct}
                />
              </div>
            </div>
          </div>
        )}
        <div style={{ minHeight: "150px" }}> </div>
      </div>
    </CalculatedDataProvider>
  );
};

export default DraftOrder;
