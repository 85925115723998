import { ProductModifier } from "@ultracommerce/react-storefront/global/src/interface/Product";

export const calStepAndMinQuantity = (
  modifiers?: ProductModifier[],
  skuConfiguration?: { [key: string]: string }
) => {
  if (skuConfiguration && skuConfiguration["packaging"]) {
    const modifier = modifiers?.find(({ key }) => key === "packaging");
    const option = modifier?.options.find(
      (option) => option.code === skuConfiguration["packaging"]
    );

    // @ts-ignore
    return { stepQty: option?.stepQty || 1, minQty: option?.minQty || 1 };
  }

  return { stepQty: 1, minQty: 1 };
};

export const calMinDeliveryDate = (leadTime: number | string) => {
  const weeks =
    (typeof leadTime === "string" ? parseInt(leadTime, 10) : leadTime) || 0;
  const today = new Date();
  today.setDate(today.getDate() + weeks * 7);
  return {
    minDeliveryDate: today.toISOString().split("T")[0],
    leadTime: weeks,
  };
};

export const genModelNumber = (
  skuCode: string,
  modifiers: ProductModifier[],
  skuConfiguration: { [key: string]: string }
) => {
  return modifiers.reduce((acc, cur) => {
    return `${acc}${skuConfiguration[cur.key]}`;
  }, skuCode);
};

export const modelNumberToSkuConfiguration = (
  modelNumber: string,
  modifiers: ProductModifier[]
) => {
  const skuConfiguration: { [key: string]: string } = {};
  modifiers.forEach((modifier, idx) => {
    const sectionOffset = modifiers.slice(0, idx).reduce((acc, mod) => {
      acc = acc + mod.options[0].code.length;
      return acc;
    }, 3);
    const selectedCode = modifier.options.find(
      (option) =>
        modelNumber
          .slice(sectionOffset, sectionOffset + modifier.options[0].code.length)
          .toLowerCase() === option.code.toLowerCase()
    );
    if (selectedCode) {
      skuConfiguration[modifier.key] = selectedCode.code;
    }
  });
  return skuConfiguration;
};
