import { useComponentData } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductDetailHeading/ProductDetailHeading.componentService";
import { ProductDetailHeadingView } from "./ProductDetailHeading.componentView";
import { ProductDetailHeadingProps } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductDetailHeading//ProductDetailHeading.componentModel";

const ProductDetailHeading = (props: ProductDetailHeadingProps) => {
  const { componentData } = useComponentData(props);

  return <ProductDetailHeadingView componentData={componentData} />;
};

export { ProductDetailHeading, type ProductDetailHeadingProps };
