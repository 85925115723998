import { useContentContext, useFormatCurrency } from "@ultracommerce/react-storefront/global";
import { ProductDetailHeadingModel } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductDetailHeading/ProductDetailHeading.componentModel";
import { useTranslation } from "react-i18next";
import { useCalculateData } from "../../../../contexts/CalculatedDataProvider";

export const ProductDetailHeadingView = ({
  componentData: { skuCode, linealMeter },
}: {
  componentData: ProductDetailHeadingModel;
}) => {
  const {
    templateData: { productDetail },
  } = useContentContext();
  const { productPageDisplayConfiguration } = productDetail || {};

  const pdpConfig: any = JSON.parse(productPageDisplayConfiguration || "{}");
  const { skuLabel } = pdpConfig || {};
  const { t } = useTranslation();
  const { calculatedData } = useCalculateData();
  const [formatCurrency] = useFormatCurrency({});
  return (
    <>
      {skuCode && (
        <div className="row">
          <div className="col">
            {!!skuLabel && <strong className="mb-2 text-muted small">{skuLabel}: </strong>}
            {calculatedData?.modelPriceInfo?.skuConfigurationPrice?.modelNumber || skuCode}
          </div>
        </div>
      )}

      {linealMeter && (
        <div className="row">
          <div className="col">
            <strong className="mb-2 text-muted small">{t("frontend.product.linealmeter")} </strong>
            {formatCurrency(parseFloat(linealMeter))}
            &nbsp;<span>{t("frontend.includegst")}</span>
          </div>
        </div>
      )}
    </>
  );
};
