import { useLocation, useNavigate } from "react-router";
import { PotentialFilter } from "@ultracommerce/react-storefront/global/src/interface/SearchProduct";
import {
  FilterUnit,
  SelectedUnit,
  getFacetUnitGroupName,
} from "./useUnitFilter";
import { Dispatch, SetStateAction } from "react";
import { processQueryParameters } from "@ultracommerce/react-storefront/global";
import queryString from "query-string";

const FacetUnitSelector = ({
  filter,
}: {
  filter: PotentialFilter & {
    units: FilterUnit[];
    setSelectedUnit: Dispatch<SetStateAction<SelectedUnit>>;
  };
}) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const params = processQueryParameters(loc.search);
  const onUnitChange = (unit: FilterUnit) => {
    if (unit.slug === filter.slug) return;
    if (params[`facet_${filter.slug}`]) {
      const newParams = { ...params };
      delete newParams[`facet_${filter.slug}`];
      navigate({
        pathname: loc.pathname,
        search: queryString.stringify(newParams, { arrayFormat: "comma" }),
      });
    }
    filter.setSelectedUnit((prevState) => ({
      ...prevState,
      [getFacetUnitGroupName(filter)]: unit.slug,
    }));
  };

  return (
    <div className="btn-group btn-group-toggle mb-2">
      {filter.units.map((unit) => (
        <button
          style={{
            padding: "0.25rem 0.5rem",
            fontSize: "0.875rem",
          }}
          key={unit.slug}
          type="button"
          className={`btn btn-primary btn-sm ${
            unit.slug === filter.slug ? "active" : ""
          }`}
          onClick={() => onUnitChange(unit)}
        >
          {unit.unit}
        </button>
      ))}
    </div>
  );
};

export { FacetUnitSelector };
