import { SetStateAction, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import useGeneralFormService from "../services/generalForm.service";
import { DynamicFormProps, Payload, InvalidTracker } from "../interface";
import { formModel } from "../dynamicFormModel.class";
import { formatPayload, validateFields } from "../services/utils";
import useAuthFormService from "../services/authForm.service";
import { toast } from "react-toastify";

export const useFormModel = (props: DynamicFormProps) => {
  const { t } = useTranslation();
  const {
    formLayout,
    formAction,
    defaultPayload = {},
    actionButtonText = formAction === "Sign Up" ? t("frontend.account.createAccount") : t("frontend.core.submit"),
    successMessage = t("frontend.contact.success_general"),
  } = props;
  let layout = JSON.parse(formLayout);
  if(typeof layout === 'object' && !Object.keys(layout).length){
    layout = []
  }
  let filteredlayout = layout?.filter((item:any)=> item.conditional).map((item:any)=> item.elements.split(',')).flat(3)
  const [payload, setPayload] = useState<Payload>(defaultPayload);
  const [invalidTracker, setInvalidTracker] = useState<InvalidTracker>({});
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const { formResponseSubmitAction } = useGeneralFormService();
  const { createAccountSubmitAction } = useAuthFormService();

  const recaptchaSitekey = useSelector((state: any) => state.configuration.site.settings.siteRecaptchaSiteKey);

  const handleFormSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (isFormSubmitting) return;
      setFormSubmitting(true);

      const { isFormValid, invalidFields } = validateFields({ formQuestions: props.formQuestions, payload, filteredlayout });
      setInvalidTracker(invalidFields);
      if (!isFormValid) {
        toast.error(t("frontend.contact.error_general"));
        setFormSubmitting(false);
        return;
      }
      const data = formatPayload(payload);
      try {
        switch (props.formAction) {
          case "General Form":
            await formResponseSubmitAction(data, props.formReference);
            break;
          case "Sign Up":
          case "Login":
            await createAccountSubmitAction(data);
            break;
          default:
            console.log("Form Submit action not supported");
        }
        setFormSubmitted(true);
      } catch (err) {
        console.log("catch", err);
      } finally {
        setFormSubmitting(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isFormSubmitting,
      createAccountSubmitAction,
      formResponseSubmitAction,
      payload,
      props.formAction,
      props.formQuestions,
      props.formReference,
    ],
  );

  const setValueFactory = useCallback(
    (attributeCode: string) => (func: SetStateAction<{}>) => {
      // returns a curried function that acts in the same way as the set response hook
      // but will delete the specified attribute code from the invalid tracker
      setPayload(func);
      setInvalidTracker((tracker) => {
        const copy = { ...tracker };
        delete copy[attributeCode];
        return copy;
      });
    },
    [],
  );

  return new formModel({
    props: { ...props, actionButtonText, successMessage },
    payload,
    isFormSubmitted,
    isFormSubmitting,
    invalidTracker,
    recaptchaVerified,
    recaptchaSitekey,
    setValueFactory,
    handleFormSubmit,
    setRecaptchaVerified,
  });
};
