import { AddProductToQuoteModalProps } from "./AddProductToQuoteModal.componentModel";
import { AddProductToQuoteModalView } from "./AddProductToQuoteModal.componentView";
import { useComponentData } from "./AddProductToQuoteModal.componentService";

const AddProductToQuoteModal = (props: any) => {
  const {
    componentData,
    componentService: {
      setQuoteModal,
      handleQuoteRedirect,
      setExistingQuoteVal,
      addToExistingQuote,
      addToNewQuote,
      setQuoteName,
    },
  } = useComponentData(props);

  const { searchListingFlag = false, product = [] } = props
  return (
    <AddProductToQuoteModalView
      componentData={componentData}
      setQuoteModal={setQuoteModal}
      setQuoteName={setQuoteName}
      handleQuoteRedirect={handleQuoteRedirect}
      addToExistingQuote={addToExistingQuote}
      addToNewQuote={addToNewQuote}
      setExistingQuoteVal={setExistingQuoteVal}
      searchListingFlag={searchListingFlag}
      product={product}
    />
  );
};

export { AddProductToQuoteModal, type AddProductToQuoteModalProps };
