import { ProductCardActionsView } from "./ProductCardActions.componentView";
// import { ProductCardActionsProps } from "@ultracommerce/react-storefront/global/src/modules/ProductCard/Components/ProductCardActions/ProductCardActions.componentModel";
import { useComponentModel } from "@ultracommerce/react-storefront/global/src/modules/ProductCard/Components/ProductCardActions/ProductCardActions.componentService";

export function ProductCardActions(props: any) {
  const { componentData, componentService } = useComponentModel({ ...props });
  
  if (!componentData.buttonStack?.length) return null;

  const onAddToCart = () => {
    componentService.onAddToCart(componentData.itemCount);
  };
  const {product} = props 
  //CUSTOM BUSINESS LOGIC HERE
  return (
    <ProductCardActionsView
      componentData={componentData}
      setQuantity={componentService.setQuantity}
      onAddToCart={onAddToCart}
      product={product}
    />
  );
}
