import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getAvailableLocales } from "@ultracommerce/react-storefront/global";
import { LocalStorageService } from "@ultracommerce/react-storefront/global";
import {useNavigate} from 'react-router-dom'

const LanguagePicker = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const wrapperRef = useRef(null);
  const [defaultSelectedLocale, setDefaultLocale] = useState("en_us");
  const getAvailableLocalesList = useSelector(getAvailableLocales)?.split(",");
  const languageNames = new Intl.DisplayNames(["en"], {
    type: "language",
  });
  const navigate = useNavigate()
  
  useEffect(() => {
    const isDefaultExistInLanguageList = getAvailableLocalesList?.filter(
      (locale) => locale === defaultSelectedLocale,
    ).length;
    if (isDefaultExistInLanguageList === 0) {
      LocalStorageService.setItem("i18nextLng", "en_us");
      setDefaultLocale("en_us");
    } else {
      setDefaultLocale(LocalStorageService.getItem("i18nextLng"));
    }
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    if (show) document.addEventListener("mousedown", handleClickOutside);
    else document.removeEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, show]);
  return (
    <li ref={wrapperRef} className="nav-item dropdown">
      <span
        onClick={() => {
          setShow(!show);
        }}
        id="navbarDropdown"
        className={`cart position-relative nav-link text-center clickable language-wid`}
      >
        <i className="fs-6 position-absolute top-1 start-100 translate-middle badge rounded-pill bg-primary"></i>
        <i className="bi bi-globe fs-4"></i>{" "}
      </span>
      <div
        className={`dropdown-menu dropdown-menu-end p-0 border-0 shadow-lg ${show && "show"}`}
        style={{ minWidth: "110px" }}
      >
        <div className="accordion change-site-dropdown">
          <ul>
            {getAvailableLocalesList?.map((value) => {
              const code = value.split("_").at(0);
              return (
                <li key={code}>
                  <button
                    type="button"
                    className={`btn link-btn ${defaultSelectedLocale === value ? "active" : ""}`}
                    onClick={() => {
                      i18next.changeLanguage(code);
                      LocalStorageService.setItem("i18nextLng", value);
                      setDefaultLocale(value);
                      toast.success(t("frontend.languagePicker.languageChanged"));
                      navigate(0)
                    }}
                  >
                    {languageNames.of(code)}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </li>
  );
};

export { LanguagePicker };
