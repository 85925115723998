import { axios, getSdkURL } from "@ultracommerce/react-storefront/global";
import { toast } from "react-toastify";
import { Payload } from "../interface";
import { useCallback } from "react";
import { getErrorMessage } from "@ultracommerce/react-storefront/global";

const stringObject = (o: any) => {
  Object.keys(o).forEach((k) => {
    o[k] = "" + o[k];
  });
  return o;
};

const useGeneralFormService = () => {
  const formResponseSubmitAction = useCallback(async (newPayload: Payload, formReference: string) => {
    const formSubmitResponse = await axios({
      method: "POST",
      withCredentials: true,
      url: `${getSdkURL()}api/scope/addFormResponse`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { ...stringObject({ ...newPayload }), formResponse: { formID: formReference } },
    }).then((resp:any) => {
      
      if (resp?.data?.failureActions?.length) toast.error(getErrorMessage(resp?.data?.errors));
      if(formReference === '8a8285e68bdcec74018bffaaa6ba00a7'  && localStorage.getItem('region') === "US" ){
        window.location.href = '/bank-authorisation';
      }
      return { ...resp?.data?.data, status: resp?.status };
    });
    for (const fileKey of Object.keys(formSubmitResponse?.uploadDetails ? formSubmitResponse.uploadDetails : {})) {
      let xmlHeaderInputs = formSubmitResponse.uploadDetails[fileKey];
      let fileHeader = { file: newPayload[`${fileKey}-file`] };
      console.log(`start file post for ${fileKey} `);
      await axios({
        method: "POST",
        url: xmlHeaderInputs?.url,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: { ...xmlHeaderInputs?.inputs, ...fileHeader },
      })
        .then((res) => {
          console.log(`end file post for ${fileKey} `, res);
        })
        .catch((err) => {
          // Handle error
          console.log("error", err);
        });
    }
  }, []);

  return {
    
    formResponseSubmitAction 
    
    
  };
};

export default useGeneralFormService;
