import { useContentContext } from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";

const Blog = () => {
  const pageData = useContentContext();
  const {
    PageModule: { DynamicPage },
    CommonModule: { BlogListing },
  } = useElementContext();

  return (
    <DynamicPage ignoreLayout={true}>
      {({ renderDynamicContent }) => (
        <>
          {renderDynamicContent()}
          <div className="blogTitleContainer p-5 text-center">
            <h1 className="blogTitle">{pageData.title}</h1>
          </div>
          <BlogListing />
          
        </>
      )}
    </DynamicPage>
  );
};

export default Blog;
