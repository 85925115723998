import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useFormatDateTime,
  useQuotesList,
} from "@ultracommerce/react-storefront/global";
import { useState } from "react";
import {
  useElementContext,
  axios,
  getSdkURL,
} from "@ultracommerce/react-storefront/global";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import ContentLoader from "react-content-loader";
import { useCustomFormatCurrency } from "../../../hooks/useFormatCurrency";

const Loader = () => (
  <ContentLoader viewBox="0 0 500 165">
    {Array.apply(null, new Array(10)).map((_, i) => (
      <rect x="0" y={24 * i} rx="4" ry="4" width="100%" height="20" key={i}/>
    ))}
  </ContentLoader>
);

const QuoteStatus = ({ type = "info", text }) => {
  return <span className={`badge bg-${type} m-0`}>{text}</span>;
};

const QuotesListItem = (props) => {
  const {
    purchaseOrderNumber,
    orderStatusType_typeCode,
    orderID,
    quoteName,
    createdDateTime,
    orderStatusType_typeName,
    calculatedTotal,
    calculatedTotalItemQuantity,
    refreshQuoteList,
    currencyCode,
  } = props;
  const [formateDate] = useFormatDateTime();
  const [formatCurrency] = useCustomFormatCurrency({});
  const MySwal = withReactContent(Swal);
  const removeSingleQuote = (orderID) => {
    MySwal.fire({
      icon: "info",
      title: <p>Are you sure, You want to remove the quote</p>,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Yes, Remove it!",
    }).then((data) => {
      if (data.isConfirmed) {
        axios({
          method: "POST",
          withCredentials: true,
          url: `${getSdkURL()}api/scope/deleteOrder`,
          data: { orderID, returnJSONObjects: "cart" },
          headers: {
            "Content-Type": "application/json",
          },
        }).then((response) => {
          if (
            response?.status === 200 &&
            response?.data?.failureActions.length === 0
          ) {
            toast.success("Quote removed successfully");
            refreshQuoteList();
          } else {
            toast.error("Error Found!");
          }
        });
      }
    });
  };

  return (
    <tr>
      <th>
        {purchaseOrderNumber ? (
          <Link
            className="nav-link-style font-weight-medium font-size-sm"
            to={`/my-account/quotes/${orderID}`}
          >
            {purchaseOrderNumber}
          </Link>
        ) : (
          "--"
        )}
        <br />
      </th>
      <th>
        <Link
          className="nav-link-style font-weight-medium font-size-sm"
          to={`/my-account/quotes/${orderID}`}
        >
          {quoteName}
        </Link>
        <br />
      </th>
      <td>
        <QuoteStatus text={orderStatusType_typeName} />
      </td>
      <td>{formateDate(createdDateTime)}</td>
      <td>{calculatedTotalItemQuantity}</td>
      <td>{formatCurrency(calculatedTotal, currencyCode)}</td>
      {["qstDraft","qstRejectedQuote"].includes(orderStatusType_typeCode) && (
        <td className="text-center">
         <span
            className="mx-2 btn-link link"
            role="button"
            onClick={() => removeSingleQuote(orderID)}
          >
            Remove
          </span>
        </td>)}
    </tr>
  );
};

const AccountQuotes = ({ crumbs, title, records }) => {
  const [createQuoteModal, setcreateQuoteModal] = useState(false);
  const [isCreatingQuote, setCreatingQuote] = useState(false);
  const {
    CommonModule: { AccountLayout, AccountContent, Modal, ListingPagination },
  } = useElementContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState(["qstDraft"]);
  const quoteStatusArray = [
    {
      code: ["qstDraft"],
      label: t("frontend.account.quote.status.draft"),
    },
    {
      code: ["qstPendingReview", "qstOrderEntryRequestUpdated", "qstRejected"],
      label: t("frontend.account.quote.status.submitted"),
    },
    {
      code: ["qstApproved","qstRejectedQuote"],
      label: t("frontend.account.quote.status.approved"),
    },
  ];

  const {
    quotes,
    currentPage,
    setCurrentPage,
    totalPage,
    recordsCount,
    isLoading,
    fetchQuoteList,
  } = useQuotesList({
    statusTypeCode: selectedFilter.join(),
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isCreatingQuote) return;
    setCreatingQuote(true);
    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/createQuote`,
      data: { quoteName: e.target.quoteName.value },
    })
      .then((response) => {
        if (
          response?.status === 200 &&
          response?.data?.failureActions.length === 0
        ) {
          navigate(`/my-account/draftorder/${response?.data.orderID}`);
        }
      })
      .finally(() => {
        setCreatingQuote(false);
      });
  };

  return (
    <>
      <AccountLayout title={t("frontend.account.quoteTitle")}>
        <AccountContent />

        <button
          className="btn btn-primary my-4 rounded-3"
          onClick={() => setcreateQuoteModal(true)}
        >
          Order Request Form
        </button>
        <nav className="nav nav-pills nav-justified">
          {quoteStatusArray.map(({ code, label }) => {
            const selectedFilteredEqual =
              selectedFilter.length === code.length &&
              selectedFilter.every((value, index) => value === code[index]);
            return (
              <button
                className={
                  selectedFilteredEqual
                    ? "nav-item nav-link active "
                    : "nav-item nav-link"
                }
                key={code}
                onClick={() => {
                  setSelectedFilter(code);
                  setCurrentPage(1);
                }}
              >
                {label}
              </button>
            );
          })}
        </nav>
        <br />
        <div className="table-responsive font-size-md">
          <table className="table table-striped table-bordered ">
            <thead>
              <tr>
                <th>PO Number</th>
                <th>{t("frontend.account.quote.name")}</th>
                <th>{t("frontend.account.quote.statusTitle")}</th>
                <th>{t("frontend.account.quote.createdDateTime")}</th>
                <th>{t("frontend.account.quote.noOfItems")}</th>
                <th>{t("frontend.account.quote.totalPrice")}</th>
              </tr>
            </thead>
            <tbody>
              {!isLoading &&
                quotes
                  ?.filter((quote) =>
                    selectedFilter.includes(quote.orderStatusType_typeCode)
                  )
                  ?.map((quote) => {
                    return (
                      <QuotesListItem
                        key={quote.orderID}
                        refreshQuoteList={fetchQuoteList}
                        {...quote}
                      />
                    );
                  })}
            </tbody>
          </table>
          {isLoading && <Loader />}
          <ListingPagination
            recordsCount={recordsCount}
            currentPage={currentPage}
            setPage={setCurrentPage}
            totalPages={totalPage}
          />
        </div>
      </AccountLayout>
      <Modal
        show={createQuoteModal}
        setShow={setcreateQuoteModal}
        title="Create Quote"
        size="xMedium"
      >
        <form onSubmit={handleSubmit} className="text-center">
          <div className="col-12 d-flex mb-2">
            <div className="col-4">
              <label className="form-label">New Quote Name*</label>
            </div>
            <div className="col-8">
              <input
                type="text"
                className="form-control"
                name="quoteName"
                required
              />
            </div>
          </div>
          <button className="btn btn-primary my-2" type="submit">
            Save
          </button>
        </form>
      </Modal>
    </>
  );
};

export { AccountQuotes };
