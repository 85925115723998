import { ProductContext, useElementContext, useGetProductImageGallery, useReview } from "@ultracommerce/react-storefront/global";
import { PropsWithChildren } from "react";
import { useLocation } from "react-router";
import { useGetProductByUrlTitle } from "@ultracommerce/react-storefront/global/src/hooks/components/ProductDetail/useGetProductByUrlTitle";
import { ReviewData } from "@ultracommerce/react-storefront/global/src/interface/ProductReview";
import { useSelector } from "react-redux";

export const ProductContextProvider = ({
  productUrlTitle,
  children,
}: PropsWithChildren<{ productUrlTitle?: string }>) => {
  const {
    CommonModule: { RedirectWithReplace },
  } = useElementContext();
  const location = useLocation();
  const urlTitle = productUrlTitle || location.pathname.split("/").pop();
  const { addressZoneName } = useSelector((state : any) => state.userReducer);
  const productWithSettings = useGetProductByUrlTitle(urlTitle);

  const { product, attributeSets, productBundle, productBundleBuildOnAccount, isFetching, error } = productWithSettings;

  const ratingData: ReviewData = useReview(urlTitle);
  const { imageGallery } = useGetProductImageGallery(urlTitle);

  if (isFetching) return <ProductDetailLoading />;
  // @ts-ignore
  if (error.isError || !product || product.skuModelSearchFlag === '1') return <RedirectWithReplace pathname="/404" />;
  //@ts-ignore
  product.modifiers = product.modifiers || (addressZoneName === "US" ? product?.modifiersUS : product?.modifiersEU);
  const data = {
    isFetching: productWithSettings.isFetching,
    product,
    attributeSets,
    productBundle,
    productBundleBuildOnAccount,
    ratingData,
    imageGallery,
  };

  return <ProductContext.Provider value={data}>{children}</ProductContext.Provider>;
};

const ProductDetailLoading = () => {
  const {
    CommonModule: { Spinner },
  } = useElementContext();
  return (
    <div className="container mt-5">
      <Spinner />
    </div>
  );
};