import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { QuoteDetailV2 } from './QuoteDetailV2';
import { getOrder, useElementContext } from '@ultracommerce/react-storefront/global';
import { useNavigate } from 'react-router';
import { Spinner } from '@ultracommerce/react-storefront/global/src/components';

const AccountQuoteDetail = React.memo((props) => {
  const {
    CommonModule: { AccountLayout },
  } = useElementContext();
  const orderID = props.path;
  const [isLoaded, setIsLoaded] = useState(false);
  const [quoteDetail, setQuoteDetail] = useState();
  const [reason, setReason] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getOrder({
            params: {
              orderID,
            },
            returnQuote: true,
            isQuote: true,
          })
        );
        const data = response.success();
        setReason(data?.reason);
        setQuoteDetail(data?.quote);
        setIsLoaded(true);
        if(!data.quote?.orderID){
          navigate('/my-account/quotes');
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [dispatch, orderID]);

  const memoizedQuoteDetail = useMemo(() => quoteDetail?.orderID, [quoteDetail]);
  if (!isLoaded )
    return (
      <div className="container">
        <Spinner />
      </div>
    );
  return (
    <AccountLayout>
      {memoizedQuoteDetail && (
        <QuoteDetailV2
          quoteDetail={quoteDetail}
          updateQuote={setQuoteDetail}
          reason={reason}
          setReason={setReason}
        />
      )}
    </AccountLayout>
  );
});

export default AccountQuoteDetail;