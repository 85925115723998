import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useTranslation } from "react-i18next";

const DraftOrderProductCard = ({ product, selectedSkus, setSelectedSkus }) => {
  const {
    CommonModule: { ProductFilterGroupList },
  } = useElementContext();
  const defaultSku = product.skus.find((sku) => sku.skuID === product.defaultSku_skuID) || product.skus[0];
  const skuCode = product.defaultSku_skuCode.trim() || defaultSku.skuCode;
  const skuUom = product?.optionGroups?.length > 0 ? product.optionGroups[0].optionGroupName : "";
  const { t } = useTranslation();

  return (
    <div className="productListItem card p-3 my-2">
      <div className="d-flex">
        <div className="flex-grow-1 w-100">
          <div className="d-flex justify-content-between">
            <div className="col-9 pb-2">
              <h5 className="mx-1">{product.productName}</h5>

              {skuCode && (
                <span className="mx-1">
                  {t("frontend.product.sku")} {skuCode}
                </span>
              )}
              {skuUom !== "" && <span className="badge bg-light text-secondary mx-1">UOM : {skuUom}</span>}
            </div>
          </div>
          <ProductFilterGroupList product={product} selectedSkus={selectedSkus} setSelectedSkus={setSelectedSkus} />
        </div>
      </div>
    </div>
  );
};

export { DraftOrderProductCard };
