import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useElementContext } from "@ultracommerce/react-storefront/global";

const ShippingFulfillment = ({
  fulfillment,
  onShipmentSelect,
  onShipmentSave,
}) => {
  const { orderFulfillmentID } = fulfillment;
  const custDetails = useSelector((state) => state.userReducer);
  const { t } = useTranslation();
  const {
    CommonModule: { FulfilmentAddressSelector },
  } = useElementContext();

  return (
    <div className="shippingFulfillment">
      <h5 className="h5 pt-1 pb-2 border-bottom mb-3 confirm-details-title">
        {t("frontend.yourDetail")}
      </h5>
      <div className="row">
        <div className="col-lg-6 col-sm-12 py-1">
          <b>{t("frontend.contact.firstName")}: &nbsp;</b>
          <span>{custDetails?.firstName}</span>
        </div>
        <div className="col-lg-6 col-sm-12 py-1">
          <b>{t("frontend.contact.lastName")}: &nbsp;</b>
          <span>{custDetails?.lastName}</span>
        </div>
        <div className="col-lg-6 col-sm-12 py-1">
          <b>{t("frontend.account.company")}: &nbsp;</b>
          <span>{custDetails?.company}</span>
        </div>
        <div className="col-lg-6 col-sm-12 py-1">
          <b>{t("frontend.contact.emailAddress")}: &nbsp;</b>
          <span>{custDetails?.primaryEmailAddress.emailAddress}</span>
        </div>
        <div className="col-lg-6 col-sm-12 py-1">
          <b>{t("frontend.contact.phoneNumber")}: &nbsp;</b>
          <span>{custDetails?.primaryPhoneNumber.phoneNumber}</span>
        </div>
      </div>

      <div className="col col-sm-12 my-2 mb-4">
        <span
          className="form-control col-5"
          style={{
            backgroundColor: "#fbc8be",
            color: "#f56c52",
            border: "1px solid #f56c52",
            fontSize: "13px",
          }}
        >
          Profile information cannot be edited from the web portal.
          <br />
          If your information needs updating please contact our CSR.
        </span>
      </div>

      {/* <OrderFulfillmentItems fulfillment={fulfillment} /> */}
      <FulfilmentAddressSelector
        fulfillment={fulfillment}
        onSelect={(value) => onShipmentSelect(value, orderFulfillmentID)}
        onSave={(values) => onShipmentSave(values, orderFulfillmentID)}
      />
    </div>
  );
};
export { ShippingFulfillment };
