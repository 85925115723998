import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isVatCountry, disableInteractionSelector } from "@ultracommerce/react-storefront/global";
import { useCallback } from "react";
import { Cart } from "@ultracommerce/react-storefront/global/src/interface/Cart";
import { removePromoCode } from "@ultracommerce/react-storefront/global";
import { useCustomFormatCurrency } from "../../hooks/useFormatCurrency";

const CartOrderSummary = () => {
  const dispatch = useDispatch();
  const cart: Cart = useSelector((state: any) => state.cart);
  const { t } = useTranslation();

  const onRemovePromoCode = useCallback(
    (promotionCode: string) => {
      dispatch(removePromoCode(promotionCode, undefined, t("frontend.cart.promo_code_removed")) as any);
    },
    [dispatch, t],
  );
  return <OrderSummary cart={cart} onRemovePromoCode={onRemovePromoCode} />;
};

export interface _Cart extends Cart {
  orderTotalSummary?: {
    "surchargeTotal": number,
    "taxTotal": number,
    "total": number,
    "totalAfterTax": number,
    "totalAfterSurcharge": number
  }
}
const OrderSummary = ({
  cart,
  onRemovePromoCode,
}: {
  cart: _Cart;
  onRemovePromoCode: (promotionCode: string) => void;
}) => {
  const [formatCurrency] = useCustomFormatCurrency({});
  const disableInteraction: boolean = useSelector(disableInteractionSelector);
  const showVat: boolean = useSelector(isVatCountry);
  const { t } = useTranslation();
  return (
    <>
      <div className="card mb-4">
        <div className="card-header">
          <h4 className="p-0 mb-0">{t("frontend.cart.summary")}</h4>
        </div>
        <ul className="list-group list-group-flush ">
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <h6 className="my-0">{(cart as any).currencyCode === "EUR" ? "Total (without surcharge and tax)" : "Order Total (including surcharges and tax)"}</h6>
            <span className="float-end">
              <strong>{(cart as any).orderTotalSummary?.total > 0 ? formatCurrency((cart as any).orderTotalSummary?.total, cart?.orderFulfillments?.at(0)?.currencyCode) : formatCurrency(0, cart?.orderFulfillments?.at(0)?.currencyCode)}</strong>
            </span>
          </li>
          {/* <li className="list-group-item d-flex justify-content-between align-items-center">
            <h6 className="my-0">{t("frontend.cart.total")} {t("frontend.cart.shippingDelivery")}</h6>
            <span className="float-end">
              <strong>{cart.fulfillmentTotal > 0 ? formatCurrency(cart.fulfillmentTotal, cart?.orderFulfillments?.at(0)?.currencyCode) : "--"}</strong>
            </span>
          </li> */}
          {<li className="list-group-item d-flex justify-content-between align-items-center">
            <h6 className="my-0">{(cart as any).currencyCode === "EUR" ? t("frontend.cart.totalSurcharge") : "Included Surcharge"}</h6>
            <span className="float-end">
              <strong>{formatCurrency((cart as any).orderTotalSummary?.surchargeTotal, cart?.orderFulfillments?.at(0)?.currencyCode)}</strong>
            </span>
          </li>}
          { !showVat && (
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <h6 className="my-0">{(cart as any).currencyCode === "EUR" ? t("frontend.cart.totalTax") : "Included Tax"}</h6>
              <span className="float-end">
                <strong>{(cart as any).orderTotalSummary?.taxTotal > 0 ? formatCurrency((cart as any).orderTotalSummary?.taxTotal, cart?.orderFulfillments?.at(0)?.currencyCode) : formatCurrency(0, cart?.orderFulfillments?.at(0)?.currencyCode)}</strong>
              </span>
            </li>
          )}
          {cart.promotionCodes.length > 0 && (
            <>
              <li className="list-group-item d-flex justify-content-between bg-light">
                <div className="text-success">
                  <h6 className="my-0"> {t("frontend.cart.discount")}</h6>
                </div>
                <span className="float-end align-center">
                  <span className="text-success">
                    {cart.discountTotal > 0 ? formatCurrency(-cart.discountTotal, cart?.orderFulfillments?.at(0)?.currencyCode) : formatCurrency(0, cart?.orderFulfillments?.at(0)?.currencyCode)}
                  </span>
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between bg-light">
                {cart.promotionCodes.map((promotionCodeItem: any) => {
                  //TODO: Review
                  const { promotionCode } = promotionCodeItem;
                  return (
                    <div key={promotionCode}>
                      <button
                        className="btn badge bg-success promo-btn"
                        type="button"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Remove Promotion"
                        key={promotionCode}
                        disabled={disableInteraction}
                        onClick={(event) => {
                          event.preventDefault();
                          onRemovePromoCode(promotionCode);
                        }}
                      >
                        <i className="bi bi-x"></i>
                        <span className="font-size-sm">{promotionCode}</span>
                      </button>
                    </div>
                  );
                })}
              </li>
            </>
          )}
          {(cart as any).currencyCode === "EUR" && <li className="list-group-item d-flex justify-content-between ">
            <h6 className="my-0">Order Total (including surcharges and tax)​</h6>
            <span className="float-end">
              <strong>{cart.calculatedTotal > 0 ? formatCurrency(cart.calculatedTotal, cart?.orderFulfillments?.at(0)?.currencyCode) : formatCurrency(0, cart?.orderFulfillments?.at(0)?.currencyCode)}</strong>
            </span>
          </li>}
          {showVat && (
            <li className="list-group-item d-flex justify-content-between ">
              <h6 className="my-0">{t("frontend.cart.vat")}</h6>
              <span className="float-end">
                <strong>{cart.VATTotal > 0 ? formatCurrency(cart.VATTotal, cart?.orderFulfillments?.at(0)?.currencyCode) : formatCurrency(0, cart?.orderFulfillments?.at(0)?.currencyCode)}</strong>
              </span>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export { OrderSummary, CartOrderSummary };
