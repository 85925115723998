import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useMemo } from "react";
import TradeOrderPayment from "./TradeOrderPayment";
import { useCustomFormatCurrency } from "../../hooks/useFormatCurrency";
import { useSelector } from "react-redux";
const DraftOrderHeader = ({
  orderInfo,
  orderFulfillment,
  setShowSummary,
  showSummary,
  orderEditable,
  quote,
  setQuoteDetail
}) => {
  const { orderStatusType, orderTotalSummary, orderPayments, currencyCode, orderItems, orderID } = quote || {};
  const navigate = useNavigate();
  const user = useSelector((state) => state.userReducer);
  const [formatCurrency] = useCustomFormatCurrency({});
  const purchaseOrderNumber = useMemo(()=>orderPayments?.[0]?.purchaseOrderNumber ?? "", [orderPayments]);
  

  const disableButton = useMemo(() => {
    return !quote?.orderItems.length || !purchaseOrderNumber;
  }, [quote?.orderItems, purchaseOrderNumber]);

  const StepsLabel = useMemo(() => {
    const outstanding = [
      !quote?.orderItems.length ? "a product" : "",
      !purchaseOrderNumber ? " PO number" : "",
    ].filter(Boolean);
    return `To proceed please add the following: ${outstanding.join(", ")}`;
  }, [purchaseOrderNumber, quote?.orderItems]);

  const validateTradeOrder = () => {
    if(!quote?.orderItems.length){
      toast.error("Please add a product");
      return;
    }else if(!purchaseOrderNumber){
        toast.error("Please add PO Number");
        return;
    }else{
      navigate('/my-account/quotes/'+orderID)
    }
  }
  return (
    <>
      <div className="d-flex justify-content-between my-4">
        {(!showSummary || !orderEditable) && (
          <button
            className="btn btn-light btn-rounded btn-outline-secondary"
            onClick={() => navigate("/my-account/quotes")}
          >
            <i className="bi bi-chevron-left"></i> &nbsp; Back to My Account
          </button>
        )}

        {showSummary && orderEditable && (
          <button
            className="btn btn-light btn-rounded btn-outline-secondary"
            onClick={() => setShowSummary(false)}
          >
            <i className="bi bi-pencil linkit"></i> &nbsp; Edit Quote
          </button>
        )}

        {disableButton && (
          <span className="alert bg-primary text-light p-2 d-flex justify-content-center align-items-center">
            {StepsLabel}
          </span>
        )}
        {!disableButton && (
          <button
            className="btn btn-primary btn-rounded"
            disabled={disableButton}
            onClick={() => validateTradeOrder()}
          >
            Submit
          </button>
        )}
      </div>
      <div className="row d-flex justify-content-between my-4">
        <div className="col-md-6 mb-4">
          <div className="d-flex align-items-center mb-2">
            <span className="badge badge-pill bg-warning">
              {orderStatusType?.typeName}
            </span>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="card p-3 bg-light border-0">
            <table className="table table-borderless m-0 text-right table-sm">
              <thead></thead>
              <tbody>
                <tr>
                  <td>Account:</td>
                  <td className="font-weight-bold text-right">
                    {user?.firstName + " " + user?.lastName}
                  </td>
                </tr>
                <tr>
                  <TradeOrderPayment
                    showSummary={showSummary}
                    poNumber={purchaseOrderNumber}
                    orderID={orderID ?? ""}
                    setQuoteDetail={setQuoteDetail}
                  />
                </tr>
                <tr>
                  <td>Total Items:</td>
                  <td className="font-weight-bold">
                    {orderItems?.length ?? 0}
                  </td>
                </tr>
                <tr>
                  <td>Order Total (Ex GST):</td>
                  <td className="font-weight-bold">
                  {(currencyCode === "EUR"
                      ? orderTotalSummary?.totalAfterSurcharge
                      : orderTotalSummary?.total) > 0
                      ? formatCurrency(
                          currencyCode === "EUR"
                            ? orderTotalSummary?.totalAfterSurcharge
                            : orderTotalSummary?.total,
                          currencyCode
                        )
                      : formatCurrency(0, currencyCode)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DraftOrderHeader;
