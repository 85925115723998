import { createContext } from "react";
import { useUtilities } from "@ultracommerce/react-storefront/global";

import { DynamicFormProps } from "./interface";
import { useFormModel } from "./hooks/useFormModel";
import { formModel } from "./dynamicFormModel.class";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'

const FormContext = createContext<formModel>(null!);

const DynamicForm = (props: DynamicFormProps) => {
  const {
    CommonModule: { SubmitButton, FormInputLayout },
  } = useElementContext();
  let { eventHandlerForWSIWYG } = useUtilities();
  const model = useFormModel(props);
  const {
    stylingCustomClasses,
    bootStrapLayoutClass,
    heading,
    description,
    successMessage,
    isFormSubmitted,
    handleFormSubmit,
  } = model;
  const {pathname} = useLocation()
  
  

  return (
    <FormContext.Provider value={model}> 
      {
        pathname==="/account-credit-application" && 
        <CreditFormDetails/>
      }
      <div className={`px-4 cetForm ${stylingCustomClasses} ${bootStrapLayoutClass}`}>
        <h3 className="text-center">{heading}</h3>
        <div
          className="text-center"
          onClick={eventHandlerForWSIWYG}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />

        {/* Form post submission success messgae */}
        {isFormSubmitted ? (
          <div className="thankyouMsg">
            <div className="text-center" dangerouslySetInnerHTML={{ __html: successMessage }} />
          </div>
        ) : (
          <form onSubmit={handleFormSubmit} acceptCharset="UTF-8">
            <FormInputLayout />
            <SubmitButton />
          </form>
        )}
      </div>
    </FormContext.Provider>
  );
};


const CreditFormDetails = () => {
  const user = useSelector((state:any)=>state.userReducer)
  return (
    <> 
      <div className="px-4 cetForm d-flex flex-wrap">
        <div className="col-12 col-md-6">
          <div className="grid-box">
          <h3 className="fw-bold">Bill To:</h3>
          {user?.company && <ListItems head="Company Name:" itemval={user?.company}/> }
          {user?.company && <ListItems head="Division/Subsidiary:" itemval={user?.company}/>}
          {user?.primaryAddress?.address?.streetAddress && <ListItems head="Address:" itemval={user?.primaryAddress?.address?.streetAddress}/>}
          {user?.primaryAddress?.address?.city && <ListItems head="City:" itemval={user?.primaryAddress?.address?.city}/>}
          {user?.primaryAddress?.address?.stateCode && <ListItems head="State:" itemval={user?.primaryAddress?.address?.stateCode}/>}
          {user?.primaryAddress?.address?.postalCode && <ListItems head="Zip:" itemval={user?.primaryAddress?.address?.postalCode}/>}
          {user?.primaryPhoneNumber?.phoneNumber && <ListItems head="Phone:" itemval={user?.primaryPhoneNumber?.phoneNumber}/>}
          {user?.primaryEmailAddress?.emailAddress && <ListItems head="Email:" itemval={user?.primaryEmailAddress?.emailAddress}/>}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="grid-box">
          <h3 className="fw-bold">Ship To:</h3>
          {user?.firstName && <ListItems head="Name:" itemval={user?.firstName}/>}
          {user?.primaryAddress?.address?.streetAddress && <ListItems head="Address:" itemval={user?.primaryAddress?.address?.streetAddress}/>}
          {user?.primaryAddress?.address?.city && <ListItems head="City:" itemval={user?.primaryAddress?.address?.city}/>}
          {user?.primaryAddress?.address?.stateCode && <ListItems head="State:" itemval={user?.primaryAddress?.address?.stateCode}/>}
          {user?.primaryAddress?.address?.postalCode && <ListItems head="Zip:" itemval={user?.primaryAddress?.address?.postalCode}/>}
          {user?.primaryPhoneNumber?.phoneNumber && <ListItems head="Phone:" itemval={user?.primaryPhoneNumber?.phoneNumber}/>}
          {user?.primaryEmailAddress?.emailAddress && <ListItems head="Email:" itemval={user?.primaryEmailAddress?.emailAddress}/>}
          </div>
        </div>
      </div>
    </>
  )
}

const ListItems = (props: any) =>{
  const {head, itemval} = props
  return (
  <div className="d-flex justify-content-between pe-4"> 
      <h5 className="fw-bold">{head}</h5>
      <p>{itemval}</p>
  </div>
  )
}


export { DynamicForm, FormContext };
