import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

// import { getMyAccountUrl } from "@ultracommerce/react-storefront/global";
import { isAuthenticated } from "@ultracommerce/react-storefront/global";
import { AccountBubbleProps } from "@ultracommerce/react-storefront/global/src/components/Header/MenuItem/AccountBubble";

const AccountBubble = ({ color = "", cssClass = "", onMenuItemClick }: AccountBubbleProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const user = useSelector((state: any) => state.userReducer);
  const { accountID, firstName } = user;

  // Changes - displays the Account icon,
  // Alignment of the icon and text in a single row,
  // Text for logged out user just says "Sign In" instead of "hello, Sign in"
  return (
    <Link
    to={isAuthenticated() ? "/my-account/profile" : "/my-account/login"}
      className={`p-2 link-button h-100 d-flex align-items-center align-self-lg-center user-account ${cssClass}`}
      style={{ color: color }}
      onClick={onMenuItemClick}
    >
      <span
        className={`d-flex align-items-center text-center ${location?.pathname === "/my-account/overview" && `active`}`}
      >
        <i className="bi bi-person-circle account-bubble-icon me-2 fs-4"></i>
        {/* <div className="fs-6 position-absolute top-1 start-100 translate-middle badge rounded-pill bg-primary">My account</div> */}
        <span className="d-block fw-bold">
          {accountID === "" || !isAuthenticated()
            ? ` ${t("frontend.account.sign_in")}`
            : ` ${t("frontend.core.hello")}, ${firstName}`}
        </span>
      </span>
    </Link>
  );
};

export { AccountBubble };
