import { useSelector } from "react-redux";
import { useElementContext } from "@ultracommerce/react-storefront/global";

const SocialLinks = ({ color, prefix = "Find Us On: " }: { color?: string; prefix?: string }) => {
  const {
    CommonModule: { SocialSiteLink },
  } = useElementContext();
  const siteSetting = useSelector((state: any) => state.configuration.site.settings);
  return (
    <>
      {(siteSetting.companySocialsInstragramUri ||
        siteSetting.companySocialsLinkedInUri ||
        siteSetting.companySocialsFaceboookUri) && (
        <div className="d-flex p-10 align-items-center">
          {prefix && <span className="me-2">{prefix}</span>}
          <SocialSiteLink color={color} uri={siteSetting?.companySocialsInstragramUri} iconClass="instagram" />
          <SocialSiteLink color={color} uri={siteSetting?.companySocialsLinkedInUri} iconClass="linkedin" />
          <SocialSiteLink color={color} uri={siteSetting?.companySocialsFaceboookUri} iconClass="facebook" />
          <SocialSiteLink color={color} uri={siteSetting?.companySocialsTwitterUri} iconClass="twitter" />
          <SocialSiteLink color={color} uri={siteSetting?.companySocialsYoutubeUri} iconClass="youtube" />
          <SocialSiteLink color={color} uri={siteSetting?.companySocialsPinterestUri} iconClass="pinterest" /> 
          <SocialSiteLink color={color} uri={siteSetting?.companySocialsWechatUri} iconClass="wechat" />
        </div>
      )}
    </>
  );
};

export { SocialLinks };
