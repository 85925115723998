import { ProductAttributesProps } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductAttributes/ProductAttributes.componentModel";
import { ProductAttributesView } from "./ProductAttributes.componentView";
import { useCopmonentData } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductAttributes/ProductAttributes.componentService";

const ProductAttributes = (props: any) => {
  const { componentData, componentService } = useCopmonentData(props);

  return <ProductAttributesView componentData={componentData} downloadFile={componentService.downloadFile} />;
};

export { ProductAttributes, type ProductAttributesProps };
