import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isVatCountry } from "@ultracommerce/react-storefront/global";
import { useCustomFormatCurrency } from "../../../hooks/useFormatCurrency";

const OrderDetails = ({ orderInfo, orderFulfillments, orderPayments }) => {
  const { t } = useTranslation();
  const [formatCurrency] = useCustomFormatCurrency({});
  const showVat = useSelector(isVatCountry);
  return (
    <>
      <div className="row">
        <div className="d-flex col-gap-10 flex-lg-nowrap flex-sm-wrap">
          <div className="card mb-4 col-sm-12 px-0">
            <div className="card-header pb-0">
              <h4 className="p-0">{t("frontend.cart.orderSummary")}</h4>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                {orderInfo.currencyCode === "EUR"
                  ? "Total (without surcharge and tax)"
                  : "Order Total (including surcharges and tax)"}
                <span className="float-end">
                  <strong>
                    {orderInfo.orderTotalSummary?.total > 0
                      ? formatCurrency(
                        orderInfo.orderTotalSummary?.total,
                        orderInfo.currencyCode
                      )
                      : formatCurrency(0, orderInfo.currencyCode)}
                  </strong>
                </span>
              </li>

              {!showVat && (
                <>
                  <li className="list-group-item">
                    {orderInfo.currencyCode === "EUR"
                      ? t("frontend.cart.totalSurcharge") : "Included Surcharge"}
                    <span className="float-end">
                      <strong>
                        {formatCurrency(
                          orderInfo?.orderTotalSummary?.surchargeTotal,
                          orderInfo.currencyCode
                        )}
                      </strong>
                    </span>
                  </li>
                  <li className="list-group-item">
                    {orderInfo.currencyCode === "EUR"
                      ? t("frontend.cart.totalTax") : "Included Tax"}
                    <span className="float-end">
                      <strong>
                        {formatCurrency(
                          orderInfo?.orderTotalSummary?.taxTotal,
                          orderInfo.currencyCode
                        )}
                      </strong>
                    </span>
                  </li>
                  {orderInfo?.currencyCode === "EUR" && (
                    <li className="list-group-item d-flex justify-content-between">
                      Order Total (including surcharges and tax)
                      <strong>
                        {formatCurrency(
                          orderInfo.orderTotalSummary?.totalAfterSurchargeAfterTax,
                          orderInfo.currencyCode
                        )
                        }
                      </strong>
                    </li>
                  )}
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export { OrderDetails };
