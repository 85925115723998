import { useTranslation } from "react-i18next";
import { useCheckoutUtilities, CHECK_PAYMENT_CODE } from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";

const PaymentList = ({ payments, onRemovePayment = () => {}, disableInteraction = false }) => {
  const {
    CommonModule: {
      GiftCardDetails,
      CCDetails,
      CheckPaymentDetails,
      TermPaymentDetails,
      ExternalPaymentDetails,
      CashPaymentDetails,
    },
  } = useElementContext();
  const { CREDIT_CARD_CODE, GIFT_CARD_CODE, TERM_PAYMENT_CODE, CASH_PAYMENT_CODE, EXTERNAL_PAYMENT_CODE } =
    useCheckoutUtilities();
  const { t } = useTranslation();
  if (payments.length === 0) return null;
  return (
    <>
      <p className="h4">{t("frontend.checkout.payments")}:</p>
      <div className="row ">
        {payments.map((payment) => {
          return (
            <div className="bg-lightgray rounded mb-5 col-12 p-3" key={payment.orderPaymentID}>
              {payment.paymentMethod.paymentMethodType === CREDIT_CARD_CODE && (
                <CCDetails hideHeading={true} creditCardPayment={payment} />
              )}
              {payment.paymentMethod.paymentMethodType === GIFT_CARD_CODE && (
                <GiftCardDetails method={payment.paymentMethod.paymentMethodType} />
              )}
              {payment.paymentMethod.paymentMethodType === TERM_PAYMENT_CODE && (
                <TermPaymentDetails hideHeading={true} termPayment={payment} />
              )}
              {payment.paymentMethod.paymentMethodType === CASH_PAYMENT_CODE && (
                <CashPaymentDetails hideHeading={true} cashPayment={payment} />
              )}
              {payment.paymentMethod.paymentMethodType === CHECK_PAYMENT_CODE && (
                <CheckPaymentDetails hideHeading={true} payment={payment} />
              )}
              {payment.paymentMethod.paymentMethodType === EXTERNAL_PAYMENT_CODE && (
                <ExternalPaymentDetails hideHeading={true} payment={payment} />
              )}
              <hr />
              <button
                className="btn btn-link px-0 text-danger"
                type="button"
                disabled={disableInteraction}
                onClick={(event) => {
                  onRemovePayment({ orderPaymentID: payment.orderPaymentID });
                }}
              >
                <i className="fal fa-times-circle"></i>
                <span className="small"> {t("frontend.core.changeSelection")}</span>
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
};
export { PaymentList };
