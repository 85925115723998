import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const QuoteToolbar = ({ delivered, orderPayments, numberText = "frontend.order.OrderNo", orderID }) => {
const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-between align-items-center my-4">
      <div className="row justify-content-between w-100 align-items-center">
        <div className="col-sm-6">
          <div className="text-muted">
            {t("frontend.order.statusText")}{" "}
            <span className="badge bg-info m-0 p-2 ml-2">{delivered.orderStatusType_typeName}</span>
          </div>
        </div>
        <div className="col-sm-6 pe-0">
          <div className="row d-flex justify-content-end gap-3">
            <Link to={`/my-account/draftorder/${orderID}`} className="btn btn-outline-secondary w-auto no-print p-2" style={{ minWidth: '25%' }}>
                <i className="bi bi-pencil me-2"></i>
                Edit
            </Link>
            <button className="btn btn-outline-secondary w-auto no-print p-2" onClick={() => window.print()} style={{ minWidth: '25%' }}>
              <i className="bi bi-printer me-2"></i> {t("frontend.order.print")}
            </button>
            {delivered.orderTemplate_orderTemplateID && (
              <div className="text-align-right mt-4 justify-content-end">
                <span>{t("frontend.account.order.history.orderFromTemplate")}</span>
                <Link to={`/my-account/subscription-orders/${delivered.orderTemplate_orderTemplateID}`}>
                  {t("frontend.account.order.history.orderFromTemplateClickHere")}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { QuoteToolbar };
